import { Component, OnInit } from '@angular/core';
import { finalize, forkJoin, Observable, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { OhhContactMethod } from '../../api/provider/models/ohh-contact-method.model';
import { OhhPaymentMethod } from '../../api/provider/models/ohh-payment-method.model';
import { OhhProvider } from '../../api/provider/models/ohh-provider.model';
import { OhhProviderLocation } from '../../api/provider/models/ohh-provider-location.model';
import { OhhProviderReview } from '../../api/provider/models/ohh-provider-review.model';
import { OhhProviderSpecialty } from '../../api/provider/models/ohh-provider-specialty.model';
import { ProviderManagerService } from '../../api/provider/provider-manager.service';

@UntilDestroy()
@Component({
    selector: 'ohh-provider-detail',
    templateUrl: './provider-detail.component.html',
    styleUrl: './provider-detail.component.less'
})
export class ProviderDetailComponent implements OnInit {

    protected readonly placeholderImageUrl = '../../../assets/images/ohh-provider-ph.png'
    protected readonly readMoreDefaultHeight = 146;

    protected providerId: string;
    protected provider: OhhProvider;
    protected ohhCdnUrl: string;
    protected profileImageUri: string;
    protected isLoading = true;
    protected isDoneLoading = false;
    protected cleanPhoneNum: string;
    protected responsiveOptions: any[] | undefined;
    protected contactMethods: OhhContactMethod[];
    protected paymentMethods: OhhPaymentMethod[];
    protected locations: OhhProviderLocation[];
    protected specialties: OhhProviderSpecialty[];
    protected reviews: OhhProviderReview[];

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private logger: ErrorLoggingService,
                private route: ActivatedRoute,
                private providerManager: ProviderManagerService,
    ) { }

    ngOnInit() {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.ohhCdnUrl = environment.ohhCdnUrl;

        this.route.paramMap
            .pipe(untilDestroyed(this))
            .subscribe({
                next: paramMap => {
                    this.providerId = paramMap.get('providerId');
                    this.getComponentData();
                }
            });

    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {

        const sources: Observable<any>[] = [
            this.providerManager.getBasicProvider(this.providerId).pipe(take(1)),
            this.providerManager.getProviderContactMethods(this.providerId).pipe(take(1)),
            this.providerManager.getProviderPaymentMethods(this.providerId).pipe(take(1)),
            this.providerManager.getProviderLocations(this.providerId).pipe(take(1)),
            this.providerManager.getProviderSpecialties(this.providerId).pipe(take(1)).pipe(),
            this.providerManager.getProviderReviews(this.providerId).pipe(take(1))
        ];

        forkJoin(sources)
            .pipe(finalize(() => {
                setTimeout(() => {
                    // After a slight delay, begin fading out the waiting indicator (the page will appear to fade in).
                    this.isDoneLoading = true;

                    // Delay removing the waiting indicator until it has completely faded out.
                    setTimeout(() => this.isLoading = false, 600);
                }, 250);
            }))
            .subscribe({
                next: ([provider, contactMethods, paymentMethods, locations, specialties, reviews]) => {
                    this.provider = provider;
                    this.contactMethods = contactMethods;
                    this.paymentMethods = paymentMethods;
                    this.locations = locations;
                    this.specialties = specialties;
                    this.reviews = reviews;
                    this.setComponentData();
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                }
            })
    }

    private setComponentData() {
        this.profileImageUri = `${this.ohhCdnUrl}/${this.provider.businessProfileImage.blobName}`
        this.cleanPhoneNum = this.provider?.providerInfo?.businessPhone.replace(/\D/g, '');
    }

}
