export class LoginResponse {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    token: string;
    expiration: Date;
    refreshToken: string;
    refreshTokenExpiration: string;

}
