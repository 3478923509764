import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { CreateOhUserModel } from './models/create-ohh-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhUser } from './models/ohh-user.model';

@Injectable({
    providedIn: 'root'
})
export class OhhUserApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.createUserUrl, HttpMethod.Post, userModel)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    updateUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.updateUserUrl, HttpMethod.Put, userModel)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    getUserById(id: string): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.getUserUrl(id), HttpMethod.Get)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}ohhUser`; }

    private static get createUserUrl(): string { return `${OhhUserApiService.baseUrl}`; }

    private static get updateUserUrl(): string { return `${OhhUserApiService.baseUrl}`; }

    private static getUserUrl(id: string): string { return `${OhhUserApiService.baseUrl}/${id}`; }

}
