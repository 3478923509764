<div id="ol-main">

    <div id="ol-main-header-container" [ngClass]="{'hide-header': !isShowingHeader}">
        <ohh-full-vw-constrained-container>
            <ohh-header></ohh-header>
        </ohh-full-vw-constrained-container>
    </div>

    <div #olMainBody id="ol-main-body-container">
        <div #scrollContainer id="ol-main-body-scroll-container" (scroll)="onScroll($event)">
            <router-outlet></router-outlet>

            <p-scrollTop target="parent" [threshold]="1000"></p-scrollTop>
        </div>
    </div>

    <!--    <div id="ol-main-footer-container" [ngClass]="{'hide-footer': !showFooter}">-->
    <!--        <ohh-footer></ohh-footer>-->
    <!--    </div>-->

    <ohh-mega-menu-top-modal *ngIf="!deviceManager.isLessThanMedium" [appendTo]="olMainBody" [(showMegaMenu)]="isShowingMegaMenu">
        <ohh-mega-menu *ngIf="isShowingMegaMenu"></ohh-mega-menu>
    </ohh-mega-menu-top-modal>

    <p-toast></p-toast>
</div>
