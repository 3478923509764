import ImageType = OhhEnums.ImageType;

import { OhhEnums } from '../../../common/enumerations/ohh.enums';

export class ImageReference {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: string;
    userId: string;
    blobName: string;
    imageType: ImageType;
    order: number = null;
    uri: string;

    get fileName(): string {
        const lastSlashIndex = this.blobName.lastIndexOf('/');
        return this.blobName.substring(lastSlashIndex + 1);
    }
}
