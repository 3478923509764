import MessageSeverity = OhhEnums.MessageSeverity;

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OhhEnums } from '../enumerations/ohh.enums';
import { OhhMessageItem } from './models/ohh-message-item.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class OhhMessageService {

    constructor(private messageService: MessageService,
                private translateService: TranslateService,
    ) { }

    askQuestion(detail: string) {
        this.messageService.add(new OhhMessageItem(this.translateService.instant('Design Question'), this.translateService.instant(detail), MessageSeverity.Warn));
    }

    showMessage(summary: string, detail: string = '', severity: MessageSeverity = MessageSeverity.Warn) {
        this.messageService.add(new OhhMessageItem(this.translateService.instant(summary), this.translateService.instant(detail), severity));
    }
}
