import PageBreak = OhhEnums.PageBreak;

import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';
import { OhhEnums } from '../enumerations/ohh.enums';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeviceManagerService {

    // Subjects
    windowResize = new Subject();						                    // Fired when application window is resized.

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceDetector: DeviceDetectorService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    get isMedium(): boolean {
        return window.innerWidth < PageBreak.Medium && window.innerWidth > PageBreak.Tablet;
    }

    get isTablet(): boolean {
        // return this.deviceDetector.isTablet() || window.innerWidth < PageBreak.Tablet;
        return window.innerWidth < PageBreak.Tablet && window.innerWidth > PageBreak.Mobile;
    }

    get isMobile(): boolean {
        return window.innerWidth < PageBreak.Mobile;
    }

    get isLessThanMedium(): boolean {
        return window.innerWidth < PageBreak.Medium;
    }
}
