import { Component } from '@angular/core';
import { ComponentBase } from '../../shared/misc/component-base';

@Component({
    selector: 'ohh-provider-sign-up',
    templateUrl: './provider-sign-up.component.html',
    styleUrl: './provider-sign-up.component.less'
})
export class ProviderSignUpComponent extends ComponentBase {

}
