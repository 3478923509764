export class RegisterUserRequest {

    constructor(userName: string, password: string, roles: string[] = null) {
        this.userName = userName;
        this.password = password;

        if (roles != null) { this.roles = roles; }
    }

    userName: string;
    password: string;
    roles: string[] = [
        "User"
    ];
}
