import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'ohh-pending-confirmation',
    templateUrl: './pending-confirmation.component.html',
    styleUrl: './pending-confirmation.component.less'
})
export class PendingConfirmationComponent {

    constructor(private router: Router,) {

    }

    onCancelWait() {
        this.router.navigate(['/']);
    }

}
