import ImageType = OhhEnums.ImageType;

import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhImageInfo } from './ohh-image-info.model';

export class OhhUser {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.lastLogin) this.lastLogin = new Date(json.lastLogin);
            if (json.images) this.images = json.images.map((image: OhhImageInfo) => new OhhImageInfo(image));
        }
    }

    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    lastLogin: Date;
    isProvider = false;
    images: OhhImageInfo[] = [];

    get profileImage(): OhhImageInfo {
        return this.images?.find((image: OhhImageInfo) => image.type == ImageType.Profile);
    }
}
