<div id="ol-services-panel-container" [ngClass]="{'fade-in': isReady}">
    <div id="ol-sp-header" class="prevent-select" [ngClass]="{'desktop': isDesktop}">{{ 'CASE_SENSITIVE.FIND_EXPERTS' | translate }}</div>
    <div id="ol-panel-wrapper">
        <p-panelMenu
				#panelMenu
                [model]="items"
                styleClass="ol-services-panel desktop"
                transitionOptions="0ms cubic-bezier(0.86, 0, 0.07, 1)"
                (toggle)="onToggle($event)"
        >
            <ng-template pTemplate="item" let-item>
<!--                <div id="ol-sp-item" style="margin-right: 10px;" [ngStyle]="{'padding': item.items?.length > 0 ? '8px 0' : 'unset', 'border-top': item.items?.length > 0 ? '1px solid #ccc' : 'unset'}">-->
                <div id="ol-sp-item" [ngClass]="{'desktop': isDesktop}">
                    <span class="prevent-select">{{ item.label }}</span>
                    <span id="ol-sp-item-icon" *ngIf="item.icon && item.items != null"><fa-icon [icon]="item.icon || ''" class="prevent-select"></fa-icon></span>
                </div>
            </ng-template>
        </p-panelMenu>
    </div>
</div>
