import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { CityManagerService } from '../../api/cities/city-manager.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { environment } from '../../../environments/environment';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { OhhCity } from '../../api/cities/models/ohh-city.model';


@UntilDestroy()
@Component({
    selector: 'ohh-neighborhood',
    templateUrl: './neighborhood.component.html',
    styleUrl: './neighborhood.component.less'
})
export class NeighborhoodComponent extends ComponentBase implements OnInit {

    protected readonly imagePath = `${environment.ohhCdnUrl}/cities/`;

    private cities: OhhCity[] = [];
    protected cityName = '';
    protected city: OhhCity;

    protected mapOptions: google.maps.MapOptions;
    // protected mapMarkers: google.maps.LatLngLiteral[] = [];
    protected mapMarkers: any[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                private cityManager: CityManagerService,
                protected deviceManager: DeviceManagerService,
                private logger: ErrorLoggingService,
                private route: ActivatedRoute,
                private router: Router,
                private sanitizer: DomSanitizer
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: any) => {
                this.getComponentData();
                setTimeout(() => this.broadcastService.scrollToTop.next(null))
            });

        this.getComponentData();
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    protected addIcon(content: string): SafeHtml {
        const iconHtml = '<i class="fas fa-caret-right"></i>'
        return this.sanitizer.bypassSecurityTrustHtml(iconHtml + content)
    }

    private getComponentData() {
        this.cityManager.getCities()
            .pipe(take(1))
            .subscribe({
                next: (cities: OhhCity[]) => {
                    this.cities = cities;

                    this.setCityName(+this.route.snapshot.queryParams['neighborhood']);
                    this.setMapOptions();
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data: cities.', { cause: err }));
                }
            })
    }

    private setCityName(cityId: number) {
        if (this.cities?.length < 1) {
            this.cityName = '';
            return;
        }

        this.city = this.cities.find(c => c.id === cityId);
        this.cityName = this.city?.name || '';
    }

    private setMapOptions() {
        this.mapOptions = null;
        this.mapMarkers = [];

        if (this.city == null) return;

        this.mapOptions = {
            mapId: '56b4a3cf738ce995',
            center: { lat: this.city.location.lat, lng: this.city.location.lng },
            zoom: 13,
            gestureHandling: 'cooperative'
        };

        // this.mapMarkers = [{ lat: this.city.location.lat, lng: this.city.location.lng }];
        this.mapMarkers = [{
            position: { lat: this.city.location.lat, lng: this.city.location.lng }
        }]
    }
}
