import { OhhCityLink } from './ohh-city-link.model';
import { OhhLatLng } from './ohh-lat-lng.model';

export class OhhCity {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.locaiton) this.location = new OhhLatLng(json.locaton);
            if (json.footerLinks) this.footerLinks = json.footerLinks.map((l: OhhCityLink) => new OhhCityLink(l));
        }
    }

    id: number;
    name: string;
    cityList: string;
    state: string;
    location: OhhLatLng;
    bannerImage: string;
    summaryText: string;
    summaryImage: string;
    highlightsIntro: string;
    highlights: string[] = [];
    footerText: string;
    footerLinks: OhhCityLink[] = [];
}
