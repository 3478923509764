import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'ohh-mega-menu-top-modal',
    templateUrl: './mega-menu-top-modal.component.html',
    styleUrl: './mega-menu-top-modal.component.less'
})
export class MegaMenuTopModalComponent implements OnInit {
    @Output() showMegaMenuChange = new EventEmitter<boolean>();

    private _showMegaMenu = false;
    @Input() set showMegaMenu(value: boolean) {
        if (value == null) return;

        this._showMegaMenu = value;
        this.showMegaMenuChange.emit(value);
    }

    get showMegaMenu(): boolean {
        return this._showMegaMenu;
    }

    @Input() appendTo: any;

    private currentUrl: string;

    constructor(private broadcastService: BroadcastService,
                private router: Router
    ) {}

    ngOnInit() {
        this.broadcastService.showMegaMenu
            .pipe(untilDestroyed(this))
            .subscribe((show: boolean) => {
                // Display the sidebar if the user is on the Services page.
                if (this.currentUrl?.includes('/services')) return;

                this.showMegaMenu = show;
            });

        this.router.events.subscribe((event: any) => {
            if (event.constructor.name === "NavigationEnd") {
                this.currentUrl = event.url;
            }
        });
    }
}
