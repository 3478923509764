import { Component } from '@angular/core';

@Component({
    selector: 'ohh-image-callout-container',
    templateUrl: './image-callout-container.component.html',
    styleUrl: './image-callout-container.component.less'
})
export class ImageCalloutContainerComponent {

}
