import { Observable, of, tap } from 'rxjs';
import { CachedCollection } from '../_common/cached-collection';
import { Injectable } from '@angular/core';
import { OhhContactMethod } from './models/ohh-contact-method.model';
import { OhhImageInfo } from '../ohh-user/models/ohh-image-info.model';
import { OhhPaymentMethod } from './models/ohh-payment-method.model';
import { OhhProvider } from './models/ohh-provider.model';
import { OhhProviderCardInfo } from './models/ohh-provider-card-info.model';
import { OhhProviderInfoUpdate } from './models/ohh-provider-info-update.model';
import { OhhProviderLocation } from './models/ohh-provider-location.model';
import { OhhProviderReview } from './models/ohh-provider-review.model';
import { OhhProviderSpecialty } from './models/ohh-provider-specialty.model';
import { ProviderApiService } from './provider-api.service';

@Injectable({
    providedIn: 'root'
})
export class ProviderManagerService {

    private _providerDetailsDict: {[providerId: string]: CachedCollection<OhhProvider>} = {};

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private providerApiService: ProviderApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getProviders(bypassCache = false): Observable<OhhProvider[]> {
        return this.providerApiService.getProviders(bypassCache);
    }

    getProvidersForSubCategory(subCategoryId: string): Observable<OhhProviderCardInfo[]> {
        return this.providerApiService.getProvidersForSubCategory(subCategoryId);
    }

    getProvider(providerId: string, bypassCache = false): Observable<OhhProvider> {
        if (!bypassCache && this._providerDetailsDict && this._providerDetailsDict[providerId] && !this._providerDetailsDict[providerId].isExpired) {
            return of(this._providerDetailsDict[providerId].collection[0]);
        }

        return this.providerApiService.getProvider(providerId)
            .pipe(tap((result: OhhProvider) => {
                this._providerDetailsDict[providerId] = new CachedCollection<OhhProvider>([result], 30)
            }));
    }

    getBasicProvider(providerId: string, bypassCache = false): Observable<OhhProvider> {
        return this.providerApiService.getBasicProvider(providerId);
    }

    getProviderContactMethods(providerId: string): Observable<OhhContactMethod[]> {
        return this.providerApiService.getProviderContactMethods(providerId);
    }

    getProviderPaymentMethods(providerId: string): Observable<OhhPaymentMethod[]> {
        return this.providerApiService.getProviderPaymentMethods(providerId);
    }

    getProviderLocations(providerId: string): Observable<OhhProviderLocation[]> {
        return this.providerApiService.getProviderLocations(providerId);
    }

    getProviderSpecialties(providerId: string): Observable<OhhProviderSpecialty[]> {
        return this.providerApiService.getProviderSpecialties(providerId);
    }

    getProviderReviews(providerId: string): Observable<OhhProviderReview[]> {
        return this.providerApiService.getProviderReviews(providerId);
    }

    getProviderGalleryImages(providerId: string): Observable<OhhImageInfo[]> {
        return this.providerApiService.getProviderGalleryImages(providerId);
    }

    updateProviderInfo(providerId: string, providerInfo: OhhProviderInfoUpdate): Observable<OhhProvider> {
        return this.providerApiService.updateProviderInfo(providerId, providerInfo)
            .pipe(tap((result: OhhProvider) => {
                this._providerDetailsDict[providerId] = new CachedCollection<OhhProvider>([result], 30)
            }));
    }

    // =========================================================================================================================================================
    // Static / Mock data
    // =========================================================================================================================================================

    getHomePageProviders(): Observable<OhhProviderCardInfo[]> {
        return this.providerApiService.getHomePageProviders();
    }

    getMockProviders(count: number): Observable<OhhProviderCardInfo[]> {
        return this.providerApiService.getMockProviders(count);
    }

}
