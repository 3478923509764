import { Observable, tap } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { LoginInfo } from './loginInfo';
import { LoginResponse } from './models/login-response.model';
import { RegisterUserRequest } from './models/register-user-request.model';
import { RegisterUserResponse } from './models/register-user-response.model';
import { ResetPwRequest } from './models/reset-pw-request.model';

@Injectable({
    providedIn: 'root'
})
export class AuthManagerService {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authApiService: AuthApiService,
                private localCacheService: LocalCacheService
    ) {

    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    ohhApiLogin(loginInfo: LoginInfo): Observable<LoginResponse> {
        return this.authApiService.ohhApiLogin(loginInfo)
            .pipe(tap((response: LoginResponse) => {
                    this.localCacheService.ohhApiAccessToken = response.token;
                    this.localCacheService.ohhApiRefreshToken = response.refreshToken
                })
            );
    }

    ohhApiLoginWithRefreshToken(): Observable<LoginResponse> {
        return this.authApiService.ohhApiLoginWithRefreshToken(`"${this.localCacheService.ohhApiRefreshToken}"`)
            .pipe(tap((response: LoginResponse) => {
                    this.localCacheService.ohhApiAccessToken = response.token;
                    this.localCacheService.ohhApiRefreshToken = response.refreshToken
                })
            );
    }

    registerUser(registerUserRequest: RegisterUserRequest): Observable<RegisterUserResponse> {
        return this.authApiService.registerUser(registerUserRequest)
    }

    confirmEmail(userId: string, token: string): Observable<any> {
        return this.authApiService.confirmEmail(userId, token);
    }

    sendEmailConfirmation(email: string): Observable<any> {
        return this.authApiService.sendEmailConfirmation(email);
    }

    forgotPassword(email: string): Observable<any> {
        return this.authApiService.forgotPassword(email);
    }

    resetPassword(model: ResetPwRequest): Observable<any> {
        return this.authApiService.resetPassword(model);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    ohhApiLogOut() {
        this.localCacheService.ohhApiAccessToken = null;
        this.localCacheService.ohhApiRefreshToken = null;
    }

}
