import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhProviderLocation } from '../provider/models/ohh-provider-location.model';

@Injectable({
    providedIn: 'root'
})
export class LocationApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getLocations(): Observable<OhhProviderLocation[]> {
        return this.apiService.apiRequest<any>(LocationApiService.getLocationsUrl, HttpMethod.Get)
            .pipe(map((result: OhhProviderLocation[]) => result.map(c => new OhhProviderLocation(c))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}locations`; }

    private static get getLocationsUrl(): string { return `${LocationApiService.baseUrl}`; }

}
