<div id="ol-sign-up" class="ol-page-container">
	<ohh-full-vw-constrained-container [isPadded]="true">
		<ohh-section-header>
<!--			<div class="ol-sh-heading">-->
<!--				<span>Become a <strong>Customer</strong></span>-->
<!--			</div>-->
			<div class="ol-sh-sub-heading">
				<strong>Reset Password</strong>
			</div>
<!--			<div id="ol-sub-text">-->
<!--                <span>As a customer you will enjoy a variety of services, including exclusive classified ads where you can find the perfect-->
<!--                match for any job.</span>-->
<!--			</div>-->
		</ohh-section-header>

		<div id="ol-sign-up-wrapper">
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<div id="ol-sign-up-container">

					<div id="ol-set-password-container">
						<div class="ol-labeled-input-text">
							<label for="password" class="prevent-select">New Password*</label>
							<input pInputText id="password" type="password" formControlName="password">
							<div class="ol-field-error" *ngIf="fieldIsInvalid('password')">
								<span *ngIf="form.controls.password?.errors?.['required']">Password is required.</span>
								<span *ngIf="form.controls.password?.errors?.['minlength']">Password must be at least 6 characters long.</span>
							</div>
						</div>
						<div class="ol-labeled-input-text">
							<label for="password" class="prevent-select">Confirm New Password*</label>
							<input pInputText id="confPassword" type="password" formControlName="confPassword">
							<div class="ol-field-error" *ngIf="fieldIsInvalid('confPassword')">
								<span *ngIf="form.controls.confPassword?.errors?.['required']">Confirm Password is required.</span>
								<span *ngIf="form.controls.confPassword?.errors?.['minlength']">Password must be at least 6 characters long.</span>
							</div>
						</div>
					</div>

					<div class="ol-form-error-box">
                        <span *ngIf="passwordMismatch && (!fieldIsInvalid('password') && fieldIsTouchedAndDirty('password')) && (!fieldIsInvalid('confPassword') && fieldIsTouchedAndDirty('confPassword'))">
                            Passwords do not match.
                        </span>
					</div>

					<div id="ol-btn-container">
						<button type="submit" class="ol-green-button" [disabled]="form.invalid">Reset Password</button>
					</div>

				</div>

			</form>
		</div>

	</ohh-full-vw-constrained-container>
</div>
