import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ohh-waiting-indicator',
    templateUrl: './waiting-indicator.component.html',
    styleUrl: './waiting-indicator.component.less'
})
export class WaitingIndicatorComponent {
    @Output() cancelWait = new EventEmitter();

    @Input() backgroundColor = 'transparent';
    @Input() blurBackground = false;
    @Input() startFadeOut = false;
    @Input() fixed = false;
    @Input() zIndex = 1;
    @Input() hideSpinner = false;
    @Input() text: string;
    @Input() subText: string;
    @Input() canCancel = false;

    protected onCancel() {
        this.cancelWait.emit();
    }
}
