import MessageSeverity = OhhEnums.MessageSeverity;

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { finalize, switchMap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { environment } from '../../../environments/environment';
import { LoginInfo } from '../../api/auth/loginInfo';
import { LoginResponse } from '../../api/auth/models/login-response.model';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ohh-log-in',
    templateUrl: './log-in.component.html',
    styleUrl: './log-in.component.less'
})
export class LogInComponent implements OnInit {

    protected isNewUser = false;
    protected isSigningIn = false;
    protected unverifiedUserEmail: any;
    protected suppressEmail = false;

    // =========================================================================================================================================================
    // Reactive Form Set up
    // =========================================================================================================================================================

    form = new FormGroup({
        email: new FormControl('', {
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', {
            validators: [Validators.required]
        })
    });

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                private messageService: OhhMessageService,
                private ohhUserManager: OhhUserManagerService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.isNewUser = this.route.snapshot.queryParamMap.get('newUser') == 'true';

        this.suppressEmail = environment.suppressEmail;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.form.invalid || this.form.controls.email.invalid || this.form.controls.password.invalid) {
            return;

            // TODO: Figure out our error notification strategy.
        }

        this.isSigningIn = true;

        this.authManager.ohhApiLogin(new LoginInfo(this.form.value.email, this.form.value.password))
            .pipe(
                finalize(() => {
                    this.isSigningIn = false
                }),
                switchMap((response: LoginResponse) => {
                    return this.ohhUserManager.getUserByToken(response.token)
                })
            )
            .subscribe({
                next: (user: OhhUser) => {
                    this.messageService.showMessage("Success!", "Log in was successful.", MessageSeverity.Success);

                    if (!this.isNewUser) {
                        this.router.navigate(['/']);
                    } else {
                        this.router.navigate(['/customer/profile'], { queryParams: { newUser: true } });
                    }
                },
                error: err => {
                    this.messageService.showMessage("Login Error", err?.error?.Error_1[0], MessageSeverity.Error);

                    if (err?.error?.Error_1[0].includes('must verify your email')) {
                        this.unverifiedUserEmail = this.form.value.email;
                    }
                }
            })
    }

    protected onSignUp() {
        this.router.navigate(['/register'])
    }

    protected onSendEmailVerification() {
        if (this.unverifiedUserEmail == null) {
            this.messageService.showMessage("Please enter your email address.");
            return;
        }

        this.authManager.sendEmailConfirmation(encodeURIComponent(this.unverifiedUserEmail))
            .subscribe({
                next: () => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.SUCCESS'),
                        this.translateService.instant('PHRASE.EMAIL_CONFIRMATION_SENT'),
                        MessageSeverity.Success);

                    setTimeout(() => this.router.navigate(['/verify-email-sent']), 1000);
                },
                error: err => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.ERROR'),
                        this.translateService.instant('PHRASE.UNABLE_TO_SEND_CONFIRMATION_LINK'),
                        MessageSeverity.Error);
                }
            })
    }

    protected onForgotPassword() {
        if (this.form.value.email == null || this.form.value.email == '') {
            this.messageService.showMessage(
                this.translateService.instant('STRINGS.ERROR'),
                this.translateService.instant('PHRASE.PLEASE_ENTER_EMAIL_ADDRESS'),
                MessageSeverity.Error
            );
            return;
        }

        this.authManager.forgotPassword(this.form.value.email)
            .subscribe({
                next: () => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.SUCCESS'),
                        this.translateService.instant('PHRASE.RESET_PASSWORD_LINK_SENT'),
                        MessageSeverity.Success);

                    setTimeout(() => this.router.navigate(['/reset-pw-email-sent'], { queryParams: { email: this.form.value.email } }), 1000);
                },
                error: err => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.ERROR'),
                        this.translateService.instant('PHRASE.UNABLE_TO_SEND_PASSWORD_RESET_LINK'),
                        MessageSeverity.Error);
                }
            })
    }

    //
    //
    //

    protected get emailIsInvalid() {
        return this.form.controls.email.touched &&
            this.form.controls.email.dirty &&
            this.form.controls.email.invalid;
    }

    protected get passwordIsInvalid() {
        return this.form.controls.password.touched &&
            this.form.controls.password.dirty &&
            this.form.controls.password.invalid;
    }

}
