import { OhhProviderInfo } from './ohh-provider-info.model';

export class OhhContactMethod {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: string;
    name: string;
    icon: string;
    iconSizePx: number
}
