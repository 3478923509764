import { Injectable } from '@angular/core';

enum cacheKey {
    OhhAccessToken = 'ohhAccessToken',
    OhhRefreshToken = 'ohhRefreshToken',

}

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {

    constructor() { }

    set ohhApiAccessToken(value: string) {
        if (value == null) {
            localStorage.removeItem(cacheKey.OhhAccessToken);
            return;
        }

        localStorage[cacheKey.OhhAccessToken] = value;
    }

    get ohhApiAccessToken(): string {
        return localStorage[cacheKey.OhhAccessToken] || '';
    }

    set ohhApiRefreshToken(value: string) {
        if (value == null) {
            localStorage.removeItem(cacheKey.OhhRefreshToken);
            return;
        }

        localStorage[cacheKey.OhhRefreshToken] = value;
    }

    get ohhApiRefreshToken(): string {
        return localStorage[cacheKey.OhhRefreshToken] || '';
    }

}
