<div id="pending-email-confirmation-container">
	<div id="pec-inner-container">
		<ohh-waiting-indicator
				[text]="'Pending email verification.'"
				[subText]="'Please check your email for verification link.'"
				[canCancel]="true"
				(cancelWait)="onCancelWait()"
		></ohh-waiting-indicator>
	</div>
</div>
