export class AzureTokenResponse {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    token: string;
}
