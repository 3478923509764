<div id="ol-carousel-container">

    <p-carousel
            styleClass="ohh-carousel"
            [value]="links"
            [numVisible]="3"
            [showIndicators]="false"
            [showNavigators]="false"
            [orientation]="!deviceManager.isLessThanMedium ? 'horizontal' : 'vertical'"
            [verticalViewPortHeight]="'825px'">

        <ng-template let-link pTemplate="item">
            <div id="ol-car-card-container" (click)="onCardClick(link)">
                <div id="ol-car-card-img-container" class="prevent-selectX" style="max-width: 100%; height: 200px; margin: 10px 20px; overflow: hidden; box-shadow: 0px 2px 5px rgba(0,0,0,0.2);">
                    <img class="prevent-select" style="width: 100%; height: 100%; object-fit: cover;"
                         [src]="imagePath + link?.image"
                         [alt]="link.text | translate"/>
                </div>
                <div id="ol-car-card-lbl-container" class="prevent-select ol-font-light" style="display: flex; justify-content: center; padding: 10px; font-family: 'proxima-nova', sans-serif; font-size: clamp(12px, calc(10px + .5vw), 18px)">
                    {{ link.text | translate | uppercase }}
                </div>
            </div>
        </ng-template>
    </p-carousel>

</div>
