import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhPaymentMethod } from '../provider/models/ohh-payment-method.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getPaymentMethods(): Observable<OhhPaymentMethod[]> {
        return this.apiService.apiRequest<any>(PaymentMethodApiService.getPaymentMethodsUrl, HttpMethod.Get)
            .pipe(map((result: OhhPaymentMethod[]) => result.map(c => new OhhPaymentMethod(c))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}paymentMethods`; }

    private static get getPaymentMethodsUrl(): string { return `${PaymentMethodApiService.baseUrl}`; }
}
