import { OhhContactMethod } from './ohh-contact-method.model';
import { OhhPaymentMethod } from './ohh-payment-method.model';
import { OhhProviderCategory } from './ohh-provider-category.model';
import { OhhProviderLocation } from './ohh-provider-location.model';
import { OhhProviderReview } from './ohh-provider-review.model';
import { OhhProviderSpecialty } from './ohh-provider-specialty.model';

export class OhhProviderInfo {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.categories) this.categories = json.categories.map((c: OhhProviderCategory) => new OhhProviderCategory(c));
            if (json.contactMethods) this.contactMethods = json.contactMethods.map((c: OhhContactMethod) => new OhhContactMethod(c));
            if (json.paymentMethods) this.paymentMethods = json.paymentMethods.map((p: OhhPaymentMethod) => new OhhPaymentMethod(p));
            if (json.reviews) this.reviews = json.reviews.map((p: OhhProviderReview) => new OhhProviderReview(p));
            if (json.locations) this.locations = json.locations
                .sort((a: OhhProviderLocation, b: OhhProviderLocation) => a.name.localeCompare(b.name))
                .map((l: OhhProviderLocation) => new OhhProviderLocation(l));

            if (json.specialties && (json.specialties[0] instanceof Object)) {
                this.specialties = json.specialties.map((s: OhhProviderSpecialty) => s.name);
            }
        }
    }

    id: string;
    businessName: string;
    businessEmail: string;
    businessPhone: string;
    service: string;
    professionalTitle: string;
    rate: number;
    contactForRate: boolean;
    isInsured = false;
    isVerified: boolean;
    introduction: string;
    experience: string;
    whyHire: string;
    services: string;
    websiteUrl: string;
    instaUrl: string;
    facebookUrl: string;
    linkedInUrl: string;
    youTubeUrl: string;
    tikTokUrl: string;
    rating: number;

    categories: OhhProviderCategory[] = [];
    contactMethods: OhhContactMethod[] = [];
    paymentMethods: OhhPaymentMethod[] = [];
    specialties: string[] = [];
    locations: OhhProviderLocation[] = [];
    reviews: OhhProviderReview[] = [];

}
