import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { AzureTokenResponse } from './models/azure-token-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AzureApiService {
    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getToken(): Observable<AzureTokenResponse> {
        return this.apiService.apiRequest<any>(AzureApiService.tokenUrl, HttpMethod.Get)
            .pipe(map((response: AzureTokenResponse) => new AzureTokenResponse(response)))
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}azureToken`; }

    private static get tokenUrl(): string { return `${AzureApiService.baseUrl}/token`; }

    private static getSasTokenUrl(blobPath: string): string { return `${AzureApiService.baseUrl}/sasToken`; }
}
