import { AzureApiService } from './azure-api.service';
import { AzureTokenResponse } from './models/azure-token-response.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AzureManagerService {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private azureApiService: AzureApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getToken(): Observable<AzureTokenResponse> {
        return this.azureApiService.getToken();
    }

}
