import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhProviderCardInfo } from '../../../api/provider/models/ohh-provider-card-info.model';
import { DeviceManagerService } from '../../../common/services/device-manager.service';

@UntilDestroy()
@Component({
    selector: 'ohh-provider-card',
    templateUrl: './provider-card.component.html',
    styleUrl: './provider-card.component.less'
})
export class ProviderCardComponent implements OnInit {

    @Output() buttonClick = new EventEmitter<string>();

    private _provider: OhhProviderCardInfo;
    @Input() set provider(value: OhhProviderCardInfo) {
        if (value == null) return;

        this._provider = value;
        if (this.provider?.image != null) {
            this.imagePath = !this.isDemo
                ? `${environment.ohhCdnUrl}/${this.provider.image.blobName}`
                : this.provider.image.blobName;
        }
        // this.rateUnit = this.getRateUnitString(this._provider?.providerInfo?.rateUnit)
        this.rateUnit = 'STRINGS.HOUR_ABBR';

        this.setProviderName();
        this.setRatingIcons();

        if (this._provider?.providerInfo?.contactForRate == true) {
            this.contactForRate = true;
        }
    }

    get provider(): OhhProviderCardInfo {
        return this._provider;
    }

    @Input() buttonText = 'CASE_SENSITIVE.VIEW_PROFILE_&_CONNECT'

    private _imagePath = '../../../assets/images/ohh-provider-ph.png';
    @Input() set imagePath(value: string) {
        if (value == null) return;

        this._imagePath = value;
    }

    get imagePath(): string {
        return this._imagePath;
    }

    @Input() isDemo = false;

    protected rateUnit: string;
    protected ratingIcons: any[];
    protected providerName = '--';
    protected contactForRate = false;
    protected isMobile = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService) {}

    ngOnInit() {
        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isMobile = this.deviceManager.isMobile);

        this.isMobile = this.deviceManager.isMobile;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onButtonClick() {
        this.buttonClick.emit(this.provider.id);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setProviderName() {
        const pi = this.provider?.providerInfo;
        if (pi == null) return;

        this.providerName = pi.businessName != null
            ? pi.businessName
            : (this.provider.firstName != null || this.provider.lastName != null)
                ? `${this.provider.firstName} ${this.provider.lastName}` : '--';
    }

    private getRateUnitString(unit: OhhEnums.RateUnit) {
        switch (unit) {
            case OhhEnums.RateUnit.Hour:
                return 'STRINGS.HOUR';
            default:
                return null;
        }
    }

    private setRatingIcons() {
        this.ratingIcons = [];
        const iconBase: any[] = ['far', 'star'];

        for (let i = 0; i < 5; i++) {
            let icon: any;

            if (i < this.provider.providerInfo.rating) {
                icon = (this.provider.providerInfo.rating - i >= 1 || this.provider.providerInfo.rating == 0) ? 'star' : 'star-half-stroke';
            } else {
                icon = iconBase;
            }

            this.ratingIcons.push(icon);
        }
    }

}
