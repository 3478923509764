import { Observable, of, tap } from 'rxjs';
import { CachedCollection } from '../_common/cached-collection';
import { Injectable } from '@angular/core';
import { OhhPaymentMethod } from '../provider/models/ohh-payment-method.model';
import { PaymentMethodApiService } from './payment-method-api.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodManagerService {

    private _paymentMethods: CachedCollection<OhhPaymentMethod>;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private paymentMethodApiService: PaymentMethodApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getPaymentMethods(bypassCache = false): Observable<OhhPaymentMethod[]> {
        if (!bypassCache && this._paymentMethods && !this._paymentMethods.isExpired) {
            return of(this._paymentMethods.collection);
        }

        // If the cache is expired, update the cache.
        const obs = this.paymentMethodApiService.getPaymentMethods()
            .pipe(tap(methods => this._paymentMethods = new CachedCollection(methods, 30)));

        // Unless we want to explicitly bypass cache, always return it, even if it is expired.
        // The next call will return updated data. This way we never have to wait for data.
        return (!bypassCache && this._paymentMethods) ? of(this._paymentMethods.collection) : obs;
    }

}
