import ImageType = OhhEnums.ImageType;

import { OhhEnums } from '../../../common/enumerations/ohh.enums';

export class AddImageModel {

    constructor(blobName: string, fileName: string, type: ImageType) {
        this.blobName = blobName;
        this.fileName = fileName;
        this.type = type;
    }

    blobName: string;
    fileName: string;
    type: ImageType;

}
