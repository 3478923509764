<div id="ol-component-container">
	<div id="ol-rpe-title">
		Email Sent
	</div>
	<div>
		<fa-icon icon="circle-check"></fa-icon>
	</div>
	<div id="ol-rpe-body" [innerHTML]="text | translate"></div>
	<div *ngIf="emailType != null && email != null" id="ol-link">
		<div *ngIf="emailType == EmailType.EmailVerify" class="ol-pseudo-link" (click)="onSendEmailVerification()">{{ 'PHRASE.RESEND_VERIFICATION_EMAIL' | translate }}</div>
		<div *ngIf="emailType == EmailType.PasswordReset" class="ol-pseudo-link" (click)="onForgotPassword()">{{ 'PHRASE.RESEND_PASSWORD_RESET_EMAIL' | translate }}</div>
	</div>
</div>
