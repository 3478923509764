import { Component } from '@angular/core';
import { ComponentBase } from '../../shared/misc/component-base';

@Component({
    selector: 'ohh-service-categories',
    templateUrl: './service-categories.component.html',
    styleUrl: './service-categories.component.less'
})
export class ServiceCategoriesComponent extends ComponentBase {

}
