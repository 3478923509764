<p-sidebar
        [(visible)]="showMegaMenu" styleClass="ohh-top-sidebar"
        [appendTo]="appendTo" position="top"
        [showCloseIcon]="false"
        [style]="{'height': '675px', 'maxHeight': '80%'}"
>
    <ng-template pTemplate="headless">
        <ng-content></ng-content>
    </ng-template>

</p-sidebar>
