import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhContactMethod } from './models/ohh-contact-method.model';
import { OhhImageInfo } from '../ohh-user/models/ohh-image-info.model';
import { OhhPaymentMethod } from './models/ohh-payment-method.model';
import { OhhProvider } from './models/ohh-provider.model';
import { OhhProviderCardInfo } from './models/ohh-provider-card-info.model';
import { OhhProviderInfoUpdate } from './models/ohh-provider-info-update.model';
import { OhhProviderLocation } from './models/ohh-provider-location.model';
import { OhhProviderReview } from './models/ohh-provider-review.model';
import { OhhProviderSpecialty } from './models/ohh-provider-specialty.model';

@Injectable({
    providedIn: 'root'
})
export class ProviderApiService {
    private tempDataUrlBase = '../../assets/data';

    private apiService: ApiService;

    private providersCache: OhhProvider[] = [];
    private homePageProvidersCache: OhhProviderCardInfo[] = [];
    private mockProviderCache: OhhProviderCardInfo[] = [];

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getProviders(bypassCache = false): Observable<OhhProvider[]> {
        // if (!bypassCache && this.providersCache != null && this.providersCache.length > 0) {
        //     return of(this.providersCache);
        // }

        // const dataUrl = `${this.tempDataUrlBase}/providers.json`;
        //
        // return this.apiService.apiRequest<any>(dataUrl, HttpMethod.Get)
        //     .pipe(map((result: OhhProvider[]) => result.map(c => new OhhProvider(c))));

        return this.apiService.apiRequest<any>(ProviderApiService.providersUrl, HttpMethod.Get)
            // .pipe(map((result: OhhProvider[]) => result.map(c => new OhhProvider(c))));
    }

    getProvidersForSubCategory(subCategoryId: string): Observable<OhhProviderCardInfo[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProvidersForSubCategoryUrl(subCategoryId), HttpMethod.Get)
            .pipe(map((result: OhhProviderCardInfo[]) =>  result.map(p => new OhhProviderCardInfo(p))));
    }

    getProvider(providerId: string): Observable<OhhProvider> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderUrl(providerId), HttpMethod.Get)
            .pipe(map((result: OhhProvider) => new OhhProvider(result)));
    }

    getBasicProvider(providerId: string): Observable<OhhProvider> {
        return this.apiService.apiRequest<any>(ProviderApiService.getBasicProviderUrl(providerId), HttpMethod.Get)
            .pipe(map((result: OhhProvider) => new OhhProvider(result)));
    }

    getProviderContactMethods(providerId: string): Observable<OhhContactMethod[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderContactMethodsUrl(providerId), HttpMethod.Get)
            .pipe(map((results: OhhContactMethod[]) => results.map(cm => new OhhContactMethod(cm))));
    }

    getProviderPaymentMethods(providerId: string): Observable<OhhPaymentMethod[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderPaymentMethodsUrl(providerId), HttpMethod.Get)
            .pipe(map((results: OhhPaymentMethod[]) => results.map(pm => new OhhPaymentMethod(pm))));
    }

    getProviderLocations(providerId: string): Observable<OhhProviderLocation[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderLocationsUrl(providerId), HttpMethod.Get)
            .pipe(map((results: OhhProviderLocation[]) => results.map(loc => new OhhProviderLocation(loc))));
    }

    getProviderSpecialties(providerId: string): Observable<OhhProviderSpecialty[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderSpecialtiesUrl(providerId), HttpMethod.Get)
            .pipe(map((results: OhhProviderSpecialty[]) => results.map(s => new OhhProviderSpecialty(s))));
    }

    getProviderReviews(providerId: string): Observable<OhhProviderReview[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderReviewsUrl(providerId), HttpMethod.Get)
            .pipe(map((results: OhhProviderReview[]) => results.map(s => new OhhProviderReview(s))));
    }

    getProviderGalleryImages(providerId: string): Observable<OhhImageInfo[]> {
        return this.apiService.apiRequest<any>(ProviderApiService.getProviderGalleryImagesUrl(providerId), HttpMethod.Get)
            .pipe(map((result: OhhImageInfo[]) => result.map(i => new OhhImageInfo(i))));
    }

    updateProviderInfo(providerId: string, providerInfo: OhhProviderInfoUpdate): Observable<OhhProvider>{
        return this.apiService.apiRequest<any>(ProviderApiService.updateProviderInfoUrl(providerId), HttpMethod.Put, providerInfo)
            .pipe(map((result: OhhProvider) => new OhhProvider(result)));
    }


    getHomePageProviders(): Observable<OhhProviderCardInfo[]> {
        if (this.homePageProvidersCache != null && this.homePageProvidersCache.length > 0) {
            return of(this.homePageProvidersCache);
        }

        const dataUrl = `${this.tempDataUrlBase}/providers.json`;

        try {
            return this.apiService.apiRequest<any>(dataUrl, HttpMethod.Get)
                .pipe(map((result: OhhProviderCardInfo[]) => {
                    this.homePageProvidersCache = result.map(c => new OhhProviderCardInfo(c));
                    return this.homePageProvidersCache;
                }));
        } catch (error) {
            console.log();
            return null;
        }
    }

    getMockProviders(count: number): Observable<OhhProviderCardInfo[]> {
        // if (this.mockProviderCache != null && this.mockProviderCache.length > 0) { return of(this.mockProviderCache); }

        this.mockProviderCache = [];
        const usedImageIndexes: number[] = [];

        for (let i = 0; i < count; i++) {
            const colorNumber = Math.floor(Math.random() * 10);
            const animalNumber = Math.floor(Math.random() * 10);
            const serviceNumber = Math.floor(Math.random() * 10);
            const serviceAdjNumber = Math.floor(Math.random() * 10);

            let imageNumber: number;
            do {
                imageNumber = Math.floor(Math.random() * 17) + 1;
            } while(usedImageIndexes.includes(imageNumber));
            usedImageIndexes.push(imageNumber);

            this.mockProviderCache.push(new OhhProviderCardInfo({
                id: i,
                image: { blobName: `${environment.ohhCdnUrl}/job-images/job-${imageNumber}.jpg`, "type": 1 },
                providerInfo: {
                    businessName: `${this.colors[colorNumber]} ${this.animals[animalNumber]}`,
                    service: `${this.serviceAdjectives[serviceAdjNumber]} ${this.services[serviceNumber]}`,
                    rate: Math.floor(Math.random() * 81) + 20,
                    isInsured: Math.random() < 0.5 ? 0 : 1,
                    isVerified: Math.random() < 0.5 ? 0 : 1,
                    rating: Math.floor(Math.random() * 5) + 1,
                    introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                    specialties: [
                        {  providerInfoId:  "", name:  'Speciality 1' },
                        {  providerInfoId:  "", name:  'Speciality 2' },
                        {  providerInfoId:  "", name:  'Speciality 3' }
                    ]
                }
            }))
        }

        return of(this.mockProviderCache);
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}providers`; }

    private static get providersUrl(): string { return `${ProviderApiService.baseUrl}`; }

    private static getProvidersForSubCategoryUrl(subCategoryId: string): string { return `${ProviderApiService.baseUrl}/category/${subCategoryId}/cards`; }

    private static getProviderUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}`;}

    private static getBasicProviderUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/basic`;}

    private static getProviderContactMethodsUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/contactMethods`;}

    private static getProviderPaymentMethodsUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/paymentMethods`;}

    private static getProviderLocationsUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/locations`;}

    private static getProviderSpecialtiesUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/specialties`;}

    private static getProviderReviewsUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/reviews`;}

    private static getProviderGalleryImagesUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}/gallery`;}

    private static updateProviderInfoUrl(providerId: string): string { return `${ProviderApiService.baseUrl}/${providerId}`;}

    // =========================================================================================================================================================
    // Mock Data
    // =========================================================================================================================================================

    private colors: string[] = [ 'Red', 'Orange', 'Blue', 'Green', 'Yellow', 'Purple', 'Magenta', 'Pink', 'Brown', 'Black' ];
    private animals: string[] = [ 'Unicorn', 'Zebra', 'Elephant', 'Falcon', 'Alligator', 'Bear', 'Tiger', 'Salmon', 'Koala', 'Rabbit']
    private services: string[] = [ 'Provider', 'Performer', 'Doer', 'Professional', "Laborer", 'Worker', 'Producer', 'Purveyor', 'Artisan', 'Helper' ];
    private serviceAdjectives: string[] = [ 'Exceptional', 'Solid', 'Good', 'Best', 'Skilled', 'Able', 'Adept', 'Experienced', 'Professional', 'Proficient' ];
}
