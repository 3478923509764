
<div id="ol-mega-menu-container" [ngClass]="{'non-modal': nonModalUsage}" >
    <div *ngIf="!hideHeader" id="ol-mm-header" class="prevent-select"><strong>AT-YOUR DOOR</strong> Services</div>
    <div id="ol-mm-col-container" style="position: relative; flex: 1;">

        <div id="ol-mm-fixed-wrapper" [ngClass]="{'non-modal': nonModalUsage}">

            <div *ngFor="let col of 5 | fill; let ic = index;" class="ol-mm-col">
                <div *ngFor="let row of 2 | fill; let ir = index" class="ol-mm-cat-content">
                    <div *ngIf="categories[(ic * 2) + ir]" class="ol-mm-cat-name">
                        <span class="prevent-select">{{ categories[(ic * 2) + ir].name }}</span>
                    </div>
                    <div *ngFor="let sc of categories[(ic * 2) + ir]?.subCategories"
                         class="ol-mm-sub-cat-name ol-hover-link"
                         (click)="onCategoryClick(sc)"
                    >
                        <span class="prevent-select">{{ sc.name }}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

