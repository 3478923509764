import { Injectable } from '@angular/core';
import { CityApiService } from './city-api.service';
import { Observable } from 'rxjs';
import { OhhCity } from './models/ohh-city.model';

@Injectable({
  providedIn: 'root'
})
export class CityManagerService {

  // =========================================================================================================================================================
  // C'tor and Lifecycle Hooks
  // =========================================================================================================================================================

  constructor(private cityApiService: CityApiService) { }

  // =========================================================================================================================================================
  // Public Methods
  // =========================================================================================================================================================

  getCities(bypassCache = false): Observable<OhhCity[]> {
    return this.cityApiService.getCities(bypassCache);
  }

}
