import { Observable, of, tap } from 'rxjs';
import { CachedCollection } from '../_common/cached-collection';
import { ContactMethodApiService } from './contact-method-api.service';
import { Injectable } from '@angular/core';
import { OhhContactMethod } from '../provider/models/ohh-contact-method.model';

@Injectable({
    providedIn: 'root'
})
export class ContactMethodManagerService {

    private _contactMethods: CachedCollection<OhhContactMethod>;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private contactMethodApiService: ContactMethodApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getContactMethods(bypassCache = false): Observable<OhhContactMethod[]> {
        if (!bypassCache && this._contactMethods && !this._contactMethods.isExpired) {
            return of(this._contactMethods.collection);
        }

        // If the cache is expired, update the cache.
        const obs = this.contactMethodApiService.getContactMethods()
            .pipe(tap(methods => this._contactMethods = new CachedCollection(methods, 30)));

        // Unless we want to explicitly bypass cache, always return it, even if it is expired.
        // The next call will return updated data. This way we never have to wait for data.
        return (!bypassCache && this._contactMethods) ? of(this._contactMethods.collection) : obs;
    }
}
