<div id="ol-customer-profile-container" class="ol-page-container">
	<ohh-section-header [topPadding]="40" [bottomPadding]="40">
		<div class="ol-sh-heading">
			<span>{{ pageTitle | translate }}</span>
		</div>
		<div *ngIf="isNewUser" class="ol-sh-sub-heading italic" [innerHTML]="'PHRASE.WELCOME_TO_APP_NAME' | translate : { appName: 'OHH.APP_NAME' | translate}"></div>
		<div *ngIf="isNewUser" class="ol-sh-body small-margin">
			{{ 'PHRASE.PROFILE_WELCOME_MSG' | translate : { appName: 'OHH.APP_NAME' | translate} }}
		</div>
	</ohh-section-header>

	<ohh-full-vw-constrained-container [bottomPadding]="80">

		<div id="ol-cp-content-container" style="position: relative;">
			<form [formGroup]="form">
				<p-tabView styleClass="ol-tab-view">
					<p-tabPanel [header]="'STRINGS.HOME' | translate">
						<div class="ol-ps-wrapper">
							<ohh-customer-profile
									[formGroup]="customerProfileGroup"
									[parentFormGroup]="form"
									[saveSuccess]="saveSuccessful"
									[ohhUser]="ohhUser"
									[(formHasChanges)]="customerProfileFormHasChanges"
									(submitForm)="onSubmit()"
							></ohh-customer-profile>
							<ohh-waiting-indicator *ngIf="!isComponentInitialized || isSaving" [zIndex]="2"></ohh-waiting-indicator>
						</div>

						<div class="ol-ps-wrapper" *ngIf="isProvider">
							<ohh-business-profile
									[formGroup]="businessProfileGroup"
									[parentFormGroup]="form"
									[user]="ohhUser"
									[provider]="provider"
									[providerInfo]="provider?.providerInfo"
									[saveSuccess]="saveSuccessful"
									[(formHasChanges)]="businessProfileFormHasChanges"
									(submitForm)="onSubmit()"
							></ohh-business-profile>
						</div>
					</p-tabPanel>

					<p-tabPanel [header]="'STRINGS.CLASSIFIED' | translate">

					</p-tabPanel>

					<p-tabPanel [header]="'STRINGS.REVIEWS' | translate">

					</p-tabPanel>

					<p-tabPanel [header]="'STRINGS.PASSWORD' | translate">

					</p-tabPanel>

				</p-tabView>
			</form>

			<ohh-waiting-indicator *ngIf="!isComponentInitialized || isSaving" [backgroundColor]="'#c8c8c81A'" [blurBackground]="true" [fixed]="false"></ohh-waiting-indicator>
		</div>

	</ohh-full-vw-constrained-container>

	<ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
		<ohh-page-footer></ohh-page-footer>
	</ohh-full-vw-constrained-container>
</div>

