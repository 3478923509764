import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceManagerService } from '../../../common/services/device-manager.service';

@UntilDestroy()
@Component({
    selector: 'ohh-read-more',
    templateUrl: './read-more.component.html',
    styleUrl: './read-more.component.less'
})
export class ReadMoreComponent implements OnInit, AfterViewInit {
    @ViewChild('contentDiv', { static: false }) contentDiv!: ElementRef;

    @Input() defaultHeight = 150;
    @Input() expandText = "STRINGS.SEE_MORE"
    @Input() collapseText = "STRINGS.SEE_LESS"
    @Input() allowSelect = false;

    protected height = 150;
    protected buttonLabel = this.expandText;
    protected contentHeight = 0
    protected hasScrollbar = false;
    protected isInitialized = false;

    private timerRef: any;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private deviceManager: DeviceManagerService
    ) { }

    ngOnInit(): void {
        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.calculateHeights();
                this.height = this.defaultHeight;
                this.buttonLabel = this.expandText;
            });

        this.height = this.defaultHeight;
    }

    ngAfterViewInit() {
        this.calculateHeights();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onToggleExpansion() {
        this.height = (this.height == this.defaultHeight) ? this.height = this.contentHeight : this.defaultHeight;

        setTimeout(() => {
            this.buttonLabel = (this.height == this.defaultHeight) ? this.expandText : this.collapseText;
        }, 550);
    }

    private calculateHeights() {
        if (this.contentDiv.nativeElement.scrollHeight == 0 || this.contentDiv.nativeElement.clientHeight == 0) {
            clearTimeout(this.timerRef);
            this.timerRef = setTimeout(() => this.calculateHeights(), 500);
        }

        setTimeout(() => {
            this.contentHeight = this.contentDiv.nativeElement.scrollHeight;
            this.hasScrollbar = this.contentHeight > this.contentDiv.nativeElement.clientHeight;

            this.timerRef2 = setTimeout(() => this.calculateHeights(), 500);

            clearTimeout(this.timerRef2);
            this.timerRef = setTimeout(() => {
                this.isInitialized = (this.contentHeight != 0 && this.defaultHeight != 0);
            }, 500);
        })
    }

    private timerRef2: any;
}
