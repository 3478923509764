<div id="ol-home-container" (scroll)="onScroll($event)">
    <div id="ol-home-content">

        <ohh-full-vw-w-img-constrained-container backgroundImage="ohh-hero-bg.jpg">
            <div class="aspect-ratio-box">
                <div class="aspect-ratio-box-inner">
                    <img class="prevent-select" src="../../../assets/images/ohh-hero-girl-tp.png" alt="">
                </div>

                <div id="ol-hero">
                    <div id="ol-hero-text">
                        <div class="prevent-select">{{ 'CASE_SENSITIVE.YOUR_AC' | translate }}</div>
                        <div class="prevent-select">{{ 'CASE_SENSITIVE.TO_DO_LIST_AC' | translate }},</div>
                        <div class="ol-strong prevent-select">{{ 'CASE_SENSITIVE.DONE_AC' | translate }}.</div>
                        <div id="ol-hero-sub-text" class="prevent-select">{{ 'PHRASE.INSTANTLY_CONNECT_WITH_EXPERTS' | translate }}</div>
                    </div>
                </div>

            </div>
        </ohh-full-vw-w-img-constrained-container>

<!--        <div style="margin-top: 20px;">-->
<!--            <ohh-search-bar></ohh-search-bar>-->
<!--        </div>-->

        <ohh-full-vw-constrained-container [isPadded]="true" [bottomPadding]="60">
            <ohh-section-header>
                <div class="ol-sh-heading">
                    <span>A <strong>LIFESTYLE DIRECTORY</strong> TO FIND<br>EXPERTS IN YOUR COMMUNITY</span>
                </div>
                <div class="ol-sh-sub-heading">
                    A Platform Redefining Convenience
                </div>
                <div class="ol-sh-body">
                    <i>{{ 'OHH.APP_NAME' | translate }}</i> is the go-to interactive directory that simplifies your search for top-tier experts. With effortless
                    navigation and direct access to professionals, we redefine convenience, freeing your time for what matters most.
                </div>
            </ohh-section-header>

            <ohh-image-callout-container class="ol-icc">
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-bolt.svg" alt="">
                    <p class="ol-icc-heading"><strong>DIRECT</strong> ACCESS TO PROS</p>
                    <div class="ol-icc-body">
                        Find top specialists in their field, all in one place and all with one click.
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-diamond.svg" alt="">
                    <p class="ol-icc-heading"><strong>VERIFIED</strong> EXPERTS</p>
                    <div class="ol-icc-body">
                        Your Go-To Team of Trusted Professionals
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-globe.svg" alt="">
                    <p class="ol-icc-heading"><strong>INTERACTIVE</strong> PROFILES</p>
                    <div class="ol-icc-body">
                        Comprehensive profiles that easily allow you to match with a local expert.
                    </div>
                </ohh-image-callout-card>
            </ohh-image-callout-container>
        </ohh-full-vw-constrained-container>

        <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground" [isPadded]="true">
            <ohh-section-header [bottomPadding]="20">
                <div class="ol-sh-heading no-sep">
                    <span style="font-weight: 100;">Your Household Needs, <span style="font-weight: 400;">Handled.</span></span>
                </div>
            </ohh-section-header>

            <ohh-carousel [categories]="categories"></ohh-carousel>

        </ohh-full-vw-constrained-container>

        <ohh-full-vw-constrained-container [isPadded]="true">
            <ohh-section-header>
                <div class="ol-sh-marquee">
                    <span style="text-transform: uppercase">Meet The Talent</span>
                </div>
                <div class="ol-sh-heading no-sep">
                    <span class="ol-uppercase">your zip code's best service <strong>SPECIALISTS</strong></span>
                </div>
                <div class="ol-sh-body small-margin">
                    From party planners to pet sitters, whatever your needs are, we've got the talent ready to lend a hand.
                </div>
            </ohh-section-header>

            <div id="ol-p-card-container">
                <ohh-provider-card
                        [provider]="providers && providers[0]"
                        buttonText="CASE_SENSITIVE.VIEW_SERVICES"
                        (buttonClick)="onProviderCardClick()"
                ></ohh-provider-card>
                <ohh-provider-card
                        [provider]="providers && providers[1]"
                        buttonText="CASE_SENSITIVE.VIEW_SERVICES"
                        (buttonClick)="onProviderCardClick()"
                ></ohh-provider-card>
                <ohh-provider-card
                        [provider]="providers && providers[2]"
                        buttonText="CASE_SENSITIVE.VIEW_SERVICES"
                        (buttonClick)="onProviderCardClick()"
                ></ohh-provider-card>
            </div>
        </ohh-full-vw-constrained-container>

        <ohh-full-vw-constrained-container [isPadded]="true" [showBorder]="true">
            <ohh-section-header>
                <div class="ol-sh-heading">
                    <span>THREE STEPS TO CLAIMING MORE <strong>"ME-TIME"</strong></span>
                </div>
                <div class="ol-sh-sub-heading">
                    We're making it easy to find the help you need, when you need it most.
                </div>
            </ohh-section-header>

            <ohh-image-callout-container class="ol-icc">
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-binocs.svg" alt="">
                    <p class="ol-icc-heading"><strong>DISCOVER</strong></p>
                    <div class="ol-icc-body">
                        Browse through our top-rated experts. Each profile highlights all the details you need to find your perfect match.
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-connect.svg" alt="">
                    <p class="ol-icc-heading"><strong>CONNECT</strong></p>
                    <div class="ol-icc-body">
                        Send your expert a direct message, making it easy to have your questions answered in real time.
                    </div>
                </ohh-image-callout-card>
                <ohh-image-callout-card class="ol-icc-three-up">
                    <img class="ol-icc-img" src="../../../assets/images/svg/ohh-check.svg" alt="">
                    <p class="ol-icc-heading"><strong>BOOK</strong></p>
                    <div class="ol-icc-body">
                        Directly book with your specialist in just a few clicks. No fuss, no middleman, consider it handled.
                    </div>
                </ohh-image-callout-card>
            </ohh-image-callout-container>
        </ohh-full-vw-constrained-container>

        <ohh-full-vw-constrained-container [isPadded]="true">
            <ohh-section-header>
                <div class="ol-sh-heading">
                    <span>CALLING ALL LOCAL <strong>SERVICE PROVIDERS</strong></span>
                </div>
                <div class="ol-sh-sub-heading">
                    Get Found. Get Booked.
                </div>
                <div class="ol-sh-body seventy-five">
                    If You're an A-List service pro looking to get your name out there, you've knocked on the right door. We're not your average directory.
                    We're an interactive hub designed to showcase the best of the best local talent. It's time to go from best kept secret to most sought-after
                    pro in the zipcode.
                </div>
            </ohh-section-header>

            <div style="background-color: #8dc73f; padding: 8px 60px; cursor: pointer; margin: 80px auto 0 auto;" (click)="onClaimClick()">
                <span class="prevent-select ol-green-btn-text">Get Started</span>
            </div>
        </ohh-full-vw-constrained-container>


        <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
            <ohh-page-footer></ohh-page-footer>
        </ohh-full-vw-constrained-container>
    </div>

</div>
