import * as moment from 'moment';

export abstract class CachedObject {
    private readonly cacheDuration: number = 5;

    protected constructor(cacheDuration?: number) {
        this.timestamp = new Date();
        if (cacheDuration != null) { this.cacheDuration = cacheDuration; }
    }

    timestamp: Date;

    public get isExpired(): boolean {
        return moment(new Date()).diff(moment(this.timestamp), 'minutes') >= this.cacheDuration;
    }
}
