import { Observable, of, tap } from 'rxjs';
import { CachedCollection } from '../_common/cached-collection';
import { Injectable } from '@angular/core';
import { LocationApiService } from './location-api.service';
import { OhhProviderLocation } from '../provider/models/ohh-provider-location.model';

@Injectable({
    providedIn: 'root'
})
export class LocationManagerService {

    private _locations: CachedCollection<OhhProviderLocation>;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private locationApiService: LocationApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getLocations(bypassCache = false): Observable<OhhProviderLocation[]> {
        if (!bypassCache && this._locations && !this._locations.isExpired) {
            return of(this._locations.collection);
        }

        // If the cache is expired, update the cache.
        const obs = this.locationApiService.getLocations()
            .pipe(tap(methods => this._locations = new CachedCollection(methods, 30)));

        // Unless we want to explicitly bypass cache, always return it, even if it is expired.
        // The next call will return updated data. This way we never have to wait for data.
        return (!bypassCache && this._locations) ? of(this._locations.collection) : obs;
    }
}
