import { Component } from '@angular/core';

@Component({
  selector: 'ohh-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrl: './under-construction.component.less'
})
export class UnderConstructionComponent {

}
