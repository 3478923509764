import ImageType = OhhEnums.ImageType;

import { AddImageModel } from './models/add-image.model';
import { ImageApiService } from './image-api.service';
import { ImageReference } from './models/image-reference.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OhhEnums } from '../../common/enumerations/ohh.enums';

@Injectable({
    providedIn: 'root'
})
export class ImageManagerService {

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private imageApiService: ImageApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    addImageReference(imageInfo: AddImageModel): Observable<ImageReference> {
        return this.imageApiService.addImageReference(imageInfo);
    }

    deleteImageReference(imageId: string): Observable<any> {
        return this.imageApiService.deleteImageReference(imageId);
    }

    getProfilePictureUri(): Observable<string> {
        return this.imageApiService.getProfilePictureUri();
    }

    getBizProfilePictureUri(): Observable<string> {
        return this.imageApiService.getBizProfilePictureUri();
    }

    getProfilePictureRefs(imageType = ImageType.Profile): Observable<ImageReference[]> {
        return this.imageApiService.getProfilePictureRefs(imageType);
    }
}
