<div id="ol-header-container" style="display: flex;">
	<ng-container *ngIf="!showTabletMenu" [ngTemplateOutlet]="stdMenu"></ng-container>
	<ng-container *ngIf="showTabletMenu" [ngTemplateOutlet]="mobileMenu"></ng-container>
</div>

<ng-template #stdMenu>
	<img class="ol-logo" src="../../../assets/images/ohhlook-logo.png" alt="" (click)="onLogoClick()">
	<div class="ol-header-menu">
		<ng-container [ngTemplateOutlet]="ctaButtons"></ng-container>
		<ohh-menu-bar *ngIf="!suppressRegistration"></ohh-menu-bar>
	</div>
</ng-template>

<ng-template #mobileMenu>
	<div id="ol-mobile-header-container">
		<div id="ol-mh-top" [ngClass]="{ 'tab-or-med': isTablet || isMedium }">
			<div id="ol-mh-logo" [ngClass]="{ 'tab-or-med': isTablet || isMedium }">
				<img class="ol-logo" src="../../../assets/images/ohhlook-logo.png" alt="" (click)="onLogoClick(true)"/>
			</div>
			<div id="ol-mh-burger" (click)="toggleMobileMenu()" [ngClass]="{ '-menu-open': showSidebar }">
				<svg width="20px" height="20px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g>
						<line x1="0" y1="17" x2="48" y2="17" stroke-width="1"/>
						<line x1="0" y1="31" x2="48" y2="31" stroke-width="1"/>
					</g>

					<g>
						<line x1="0" y1="24" x2="48" y2="24" stroke-width="1"/>
						<line x1="0" y1="24" x2="48" y2="24" stroke-width="1"/>
					</g>
				</svg>
			</div>
			<div *ngIf="!isMobile && !isTablet" id="ol-mh-pad"></div>
			<ng-container *ngIf="!isMobile" [ngTemplateOutlet]="mobileCtaButtons"></ng-container>
		</div>
		<ng-container *ngIf="isMobile" [ngTemplateOutlet]="mobileCtaButtons"></ng-container>
	</div>
</ng-template>

<ng-template #ctaButtons>
	<div id="ol-fe-btn" class="ol-btn" (click)="onFindExperts()">
		<span class="prevent-select">{{ 'CASE_SENSITIVE.FIND_EXPERTS' | translate }}</span>
	</div>
	<div id="ol-gyb-btn" class="ol-btn" (click)="onNewProvider()">
		<span class="prevent-select">{{ 'CASE_SENSITIVE.GROW_YOUR_BUSINESS' | translate }}</span>
	</div>
</ng-template>

<ng-template #mobileCtaButtons>
	<div id="ol-mh-bottom">
		<div class="ol-mh-btn" (click)="onFindExperts()"><span class="prevent-select">{{ 'CASE_SENSITIVE.FIND_EXPERTS' | translate }}</span></div>
		<div class="ol-mh-btn inverse" (click)="onNewProvider(true)"><span class="prevent-select">{{ 'CASE_SENSITIVE.GROW_YOUR_BUSINESS' | translate }}</span>
		</div>
	</div>
</ng-template>

<p-sidebar
    #sidebarRef
	[(visible)]="showSidebar"
	styleClass="ohh-mobile-sidebar"
	[showCloseIcon]="true"
	[position]="'right'"
	[modal]="true"
	[transitionOptions]="'600ms cubic-bezier(0.4, 0, 0.2, .5)'"
>
	<ng-template pTemplate="headless">
		<div id="ol-ls-container">
			<div id="ol-ls-header" *ngIf="!suppressRegistration && isUserLoggedIn">
				<ohh-menu-bar></ohh-menu-bar>
			</div>
			<div id="ol-ls-body-container">
				<div id="ol-lsb-scroll-container">
					<div id="ol-lsb-content">
						<ohh-services-panel (categorySelected)="onCategorySelected($event)"></ohh-services-panel>
						<div class="ol-lsb-mnu-item-group">
							<div class="ol-lsb-mnu-item" (click)="onNewProvider(true)">
								<span class="prevent-select">{{ "CASE_SENSITIVE.OFFER_YOUR_SERVICES" | translate }}</span>
							</div>
							<div *ngIf="!suppressRegistration && !isUserLoggedIn" class="ol-lsb-mnu-item" (click)="onSignInOut()">
								<span class="prevent-select">{{ "CASE_SENSITIVE.NEW_CUSTOMER" | translate }}</span>
							</div>
							<div *ngIf="!suppressRegistration && !isUserLoggedIn" class="ol-lsb-mnu-item" (click)="onSignInOut()">
								<span class="prevent-select">{{ loginLabel | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</p-sidebar>
