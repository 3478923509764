import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../common/services/broadcast.service';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { MenuItem } from 'primeng/api';
import { OhhServiceCategory } from '../../../api/service-category/models/ohh-service-category.model';
import { PanelMenu } from 'primeng/panelmenu';
import { Router } from '@angular/router';
import { ServiceCategoryManager } from '../../../api/service-category/service-category-manager.service';
import { take } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'ohh-services-panel',
    templateUrl: './services-panel.component.html',
    styleUrl: './services-panel.component.less'
})
export class ServicesPanelComponent implements OnInit {
    @ViewChild('panelMenu') panelMenu: PanelMenu

    @Output() categorySelected = new EventEmitter<number>();

    @Input() isDesktop = false;

    items: MenuItem[] = [];
    isReady = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private logger: ErrorLoggingService,
                private router: Router,
                private serviceCategoryManager: ServiceCategoryManager
    ) { }

    ngOnInit() {
        this.broadcastService.showMobileMenu
            .pipe(untilDestroyed(this))
            .subscribe((show: boolean) => {
                if (!show) {
                    this.items = this.items.map(item => {
                        const { items, ...rest } = item;
                        return { ...rest };
                    });
                }
            });

        setTimeout(() => {
            this.panelMenu.collapseAll();
            this.getComponentData();
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onToggle(event: any) {
        setTimeout(() => {
            this.resetMenuItemStatus(event.item);
            event.item.icon = event.item.expanded ? 'chevron-up' : 'chevron-down'
        });
    }

    protected onGoToCategory(event: any) {
        const state = event?.item?.state;
        if (state == null) { return; }

        this.router.navigate(['/services'], { queryParams: { category: state.parentId, subCategory: state.id } });
        this.categorySelected.emit(state.id);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {
        this.serviceCategoryManager.getCategories()
            .pipe(take(1))
            .subscribe({
                next: (categories: OhhServiceCategory[]) => {
                    this.buildPanelItems(categories);
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data: service categories.', { cause: err }));
                }
            });
    }

    private resetMenuItemStatus(selectedItem: any) {
        this.items.forEach(item => {
            if (selectedItem === item || selectedItem.items == null || selectedItem.items.length < 1) {return;}

            item.expanded = false;
            item.icon = item.items?.length > 0 ? 'chevron-down' : null;
        });
    }

    private buildPanelItems(categories: OhhServiceCategory[]) {
        this.items = [];

        categories.forEach((category: OhhServiceCategory) => {
            const item: MenuItem = {
                label: category.name,
                icon: category.subCategories?.length > 0 ? 'chevron-down' : null,
                command: category.subCategories?.length > 0 ? this.onToggle.bind(this) : null,
                items: []
            };

            category.subCategories?.forEach((sc: OhhServiceCategory) => {
                item.items.push({
                    label: sc.name,
                    state: { id: sc.id, parentId: category.id },
                    command: this.onGoToCategory.bind(this)
                });
            })

            this.items.push(item);
        })

        setTimeout(() => this.isReady = true, 100);
    }

}
