import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhServiceCategory } from './models/ohh-service-category.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceCategoryApiService {

    private tempDataUrlBase = '../../assets/data';

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getCategoriesForHomePage(bypassCache = false): Observable<OhhServiceCategory[]> {
        const dataUrl = `${this.tempDataUrlBase}/serviceCategories.json`;

        return this.apiService.apiRequest<any>(dataUrl, HttpMethod.Get)
            .pipe(map((result: OhhServiceCategory[]) => result.map(c => new OhhServiceCategory(c))));
    }

    getCategories(): Observable<OhhServiceCategory[]> {
        return this.apiService.apiRequest<any>(ServiceCategoryApiService.categoriesUrl, HttpMethod.Get)
            .pipe(map((result: OhhServiceCategory[]) => result.map(c => new OhhServiceCategory(c))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}categories`; }

    private static get categoriesUrl(): string { return `${ServiceCategoryApiService.baseUrl}`; }
}
