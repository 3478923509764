import { Component, Input, OnInit } from '@angular/core';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { environment } from '../../../../environments/environment';
import { OhhCityLink } from '../../../api/cities/models/ohh-city-link.model';
import { Router } from '@angular/router';

@Component({
    selector: 'ohh-footer-carousel',
    templateUrl: './footer-carousel.component.html',
    styleUrl: './footer-carousel.component.less'
})
export class FooterCarouselComponent implements OnInit {

    private _links: OhhCityLink[] = [];
    @Input() set links(value: OhhCityLink[]) {
        if (value == null) return;

        this._links = value;
    }

    get links(): OhhCityLink[] {
        return this._links;
    }

    protected imagePath: string;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected deviceManager: DeviceManagerService,
                private router: Router) {}

    ngOnInit(): void {
        this.imagePath = `${environment.ohhCdnUrl}/cities/`
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCardClick(link: any) {
        // this.router.navigate(['/services'], { queryParams: { category: category.id } });
        if (link?.link == null) return;

        setTimeout(() => window.open(link.link, '_blank'));
    }

}
