import { Component, Input } from '@angular/core';

@Component({
    selector: 'ohh-full-vw-w-img-constrained-container',
    templateUrl: './full-vw-w-img-constrained-container.component.html',
    styleUrl: './full-vw-w-img-constrained-container.component.less'
})
export class FullVwWImgConstrainedContainerComponent {

    private _baseImagePath = '../../../../assets/images/'
    @Input() set baseImagePath(value: string) {
        if (value == null) return;

        this._baseImagePath = value;
    }

    get baseImagePath(): string {
        return this._baseImagePath;
    }

    private _backgroundImage: string;
    @Input() set backgroundImage(value: string) {
        if (value == null) return;

        this._backgroundImage = `${this.baseImagePath}${value}`;
    }

    get backgroundImage() {
        return this._backgroundImage;
    }

}
