import MessageSeverity = OhhEnums.MessageSeverity;

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { FormValidationService } from '../../common/services/form-validation.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { ResetPwRequest } from '../../api/auth/models/reset-pw-request.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ohh-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.less'
})
export class ResetPasswordComponent implements OnInit {
    protected token: string;
    protected email: string;
    protected password: string;

    form = new FormGroup({
        password: new FormControl('', {
            validators: [Validators.required, Validators.minLength(6)]
        }),
        confPassword: new FormControl('', {
            validators: [Validators.required, Validators.minLength(6)]
        })
    });

    constructor(private route: ActivatedRoute,
                private authManager: AuthManagerService,
                private formValidationService: FormValidationService,
                private messageService: OhhMessageService,
                private router: Router,
                private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
            this.email = params['email'];
        });

        this.form.setValidators(this.formValidationService.passwordMatchValidator());
        this.form.updateValueAndValidity();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.form.invalid || this.form.controls.password.invalid) { return; }

        const model = new ResetPwRequest(this.email, this.form.controls.password.value, this.token)

        this.authManager.resetPassword(model)
            .subscribe({
                next: () => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.SUCCESS'),
                        this.translateService.instant('PHRASE.PASSWORD_RESET_SUCCESS'),
                        MessageSeverity.Success);

                    setTimeout(() => this.router.navigate(['/log-in']), 1000);
                },
                error: (err) => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.ERROR'),
                        this.translateService.instant('PHRASE.PASSWORD_RESET_FAIL'),
                        MessageSeverity.Error);
                }
            })
    }

    // =========================================================================================================================================================
    // Filed Validation
    // =========================================================================================================================================================

    protected get passwordMismatch() {
        return this.form.errors?.['passwordMismatch'];
    }

    protected fieldIsInvalid(fieldName: string) {
        return this.form.get(fieldName).invalid && this.form.get(fieldName).touched && this.form.get(fieldName).dirty;
    }

    protected fieldIsTouchedAndDirty(fieldName: string) {
        return this.form.get(fieldName).touched || this.form.get(fieldName).dirty;
    }
}
