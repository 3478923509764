import { Component, Input } from '@angular/core';

@Component({
    selector: 'ohh-full-vw-constrained-container',
    templateUrl: './full-vw-constrained-container.component.html',
    styleUrl: './full-vw-constrained-container.component.less'
})
export class FullVwConstrainedContainerComponent {

    @Input() backgroundColor = 'white';
    @Input() isPadded = false;
    @Input() showBorder = false;
    @Input() bottomPadding: number;
    @Input() topPadding: number;
    @Input() bottomMargin: number;
    @Input() sidePadding: number;

}
