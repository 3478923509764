import { OhhImageInfo } from '../../ohh-user/models/ohh-image-info.model';
import { OhhProviderInfo } from './ohh-provider-info.model';

export class OhhProviderCardInfo {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.providerInfo) this.providerInfo = new OhhProviderInfo(json.providerInfo);
            if (json.image) this.image = new OhhImageInfo(json.image);
        }
    }

    id: string;
    email: string;
    firstName: string;
    lastName: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    image: OhhImageInfo;
    providerInfo: OhhProviderInfo;
}
