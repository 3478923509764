export class OhhProviderCategory {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.subCategories) this.subCategories = json.subCategories.map((c: OhhProviderCategory) => new OhhProviderCategory(c));
        }
    }

    // categoryId: string;
    // categoryName: string;
    // subCategoryId: string;
    // subCategoryName: string;

    id: string;
    name: string;
    subCategories: OhhProviderCategory[] = [];
}
