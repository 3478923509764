import { Component, OnInit } from '@angular/core';
import { CityManagerService } from '../../../api/cities/city-manager.service';
import { DeviceManagerService } from '../../../common/services/device-manager.service';
import { environment } from '../../../../environments/environment';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { OhhCity } from '../../../api/cities/models/ohh-city.model';
import { Router } from '@angular/router';
import { take } from 'rxjs';

@Component({
    selector: 'ohh-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrl: './page-footer.component.less'
})
export class PageFooterComponent implements OnInit {

    protected cities: OhhCity[] = [];
    protected suppressRegistration = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private cityManager: CityManagerService,
                protected deviceManager: DeviceManagerService,
                private logger: ErrorLoggingService,
                private router: Router
    ) { }

    ngOnInit() {
        this.suppressRegistration = environment.suppressRegistration;

        this.getComponentData();
    }

    // =========================================================================================================================================================
    // Event Handler
    // =========================================================================================================================================================

    protected onCityClick(city: OhhCity): void {
        this.router.navigate(['/neighborhood'], { queryParams: { neighborhood: city.id } });
    }

    protected onNavigate(route: string) {
        this.router.navigate([`${route}`]);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {
        this.cityManager.getCities()
            .pipe(take(1))
            .subscribe({
                next: (cities: OhhCity[]) => {
                    this.cities = cities;
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data: cities.', { cause: err }));
                }
            })
    }

    protected readonly Math = Math;
}
