import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CropperImageInfo } from './models/cropper-image-info.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'ohh-image-cropper',
    templateUrl: './ohh-image-cropper.component.html',
    styleUrl: './ohh-image-cropper.component.less'
})
export class OhhImageCropperComponent implements OnInit {

    @Output() public save = new EventEmitter<CropperImageInfo>();
    @Output() public cancel = new EventEmitter();

    @Input() public currentImageUri: string;
    @Input() initImage: File;
    @Input() imgRatioNumerator = 1;
    @Input() imgRatioDenominator = 1;
    @Input() containWithinAspectRatio = false

    imageChangedEvent: any = '';
    croppedImage: any = '';
    file: File;

    selectedFile: File;
    croppedBlob: Blob;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    ngOnInit() {
        if (this.containWithinAspectRatio == true) {
            this.imgRatioNumerator = 1;
            this.imgRatioDenominator = 1;
        }
    }

    // blobToFile(blob: Blob, fileName: string): File {
    //     return new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
    // }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected fileChangeEvent(event: any): void {
        this.initImage = null;
        this.imageChangedEvent = event;
        this.selectedFile = event.target.files[0];
    }

    protected imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.objectUrl;
        this.croppedBlob = event.blob;
    }

    protected saveImage() {
        const fileName = this.initImage == null ? this.selectedFile.name : this.initImage.name;
        this.save.emit(new CropperImageInfo(fileName, this.croppedBlob))
    }

    protected onCancel() {
        this.cancel.emit();
    }
}
