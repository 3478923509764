import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, take } from 'rxjs';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { environment } from '../../../environments/environment';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { OhhProviderCardInfo } from '../../api/provider/models/ohh-provider-card-info.model';
import { OhhServiceCategory } from '../../api/service-category/models/ohh-service-category.model';
import { ProviderManagerService } from '../../api/provider/provider-manager.service';
import { Router } from '@angular/router';
import { ServiceCategoryManager } from '../../api/service-category/service-category-manager.service';

@Component({
    selector: 'ohh-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.less'
})
export class HomeComponent extends ComponentBase implements OnInit {

    protected heroUrl = '';
    protected categories: OhhServiceCategory[] = [];
    protected providers: OhhProviderCardInfo[] = [];

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                private logger: ErrorLoggingService,
                private ohhMessageService: OhhMessageService,
                private router: Router,
                private serviceCategoryManager: ServiceCategoryManager,
                private providerManager: ProviderManagerService
    ) {
        super(broadcastService);
    }

    override ngOnInit(): void {
        this.heroUrl = `${environment.ohhCdnUrl}/hero-ohhlook-sm.png`

        this.getComponentData();

        setTimeout(() => this.checkForScrollbar());
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onScroll(event: any) {
        this.toggleFooter(event.target);
    }

    onCardClick(category: any) {
        this.router.navigate(['/services'], { queryParams: { category: category.id } });
    }

    onFindExpertClick() {
        this.router.navigate(['/service-categories']);
    }

    onClaimClick() {
        this.router.navigate(['/early-sign-up']);
    }

    onProviderCardClick() {
        this.router.navigate(['/service-categories']);
    }

    onNotImplementedClick() {
        this.ohhMessageService.showMessage('Not Implemented', 'This area is not yet interactive.', MessageSeverity.Info);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private getComponentData() {

        const sources: Observable<any>[] = [
            this.serviceCategoryManager.getCategoriesForHomePage().pipe(take(1)),
            this.providerManager.getHomePageProviders().pipe(take(1)),
        ]

        forkJoin(sources)
            .subscribe({
                next: ([categories, providers]) => {
                    this.categories = categories;
                    this.providers = providers;
                },
                error: err => {
                    this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                }
            })

    }

    private hasVertScrollbar = false;
    private isShowingFooter = true;
    private prevScrollTop = 0;
    private readonly ANDROID_DYNAMIC_FOOTER_PAD = 2;

    private checkForScrollbar() {
        const element = document.getElementById('ol-home-container');
        this.hasVertScrollbar = element != null && (element.scrollHeight > element.clientHeight);
        this.toggleFooter(<HTMLElement>element);
    }

    toggleFooter(el: HTMLElement) {
        if (!this.hasVertScrollbar) {
            this.isShowingFooter = true;
        } else {
            // If the footer is already showing, and we've scrolled farther down the page, no need to reevaluate.
            if (this.isShowingFooter && this.prevScrollTop < el.scrollTop) {
                return;
            }

            this.isShowingFooter = el.scrollTop + el.clientHeight + this.ANDROID_DYNAMIC_FOOTER_PAD >= el.scrollHeight;
            this.prevScrollTop = el.scrollTop;
        }

        this.broadcastService.showFooter.next(this.isShowingFooter);
    }

}
