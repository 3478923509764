import { Component, Input } from '@angular/core';
import { OhhProviderReview } from '../../../api/provider/models/ohh-provider-review.model';

@Component({
    selector: 'ohh-review-viewer',
    templateUrl: './review-viewer.component.html',
    styleUrl: './review-viewer.component.less'
})
export class ReviewViewerComponent {

    @Input() heightInPx = 300;
    @Input() maxWidthInPx: number = null;
    @Input() reviews: OhhProviderReview[] = [];

}
