import { Component } from '@angular/core';

@Component({
    selector: 'ohh-image-callout-card',
    templateUrl: './image-callout-card.component.html',
    styleUrl: './image-callout-card.component.less'
})
export class ImageCalloutCardComponent {

}
