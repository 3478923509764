import { Component, Input } from '@angular/core';

@Component({
    selector: 'ohh-provider-icon-list-card',
    templateUrl: './provider-icon-list-card.component.html',
    styleUrl: './provider-icon-list-card.component.less'
})
export class ProviderIconListCardComponent {

    @Input() name: string;
    @Input() fontSize: string = '19px';

    private _icon: any = 'circle';
    @Input() set icon(value: any) {
        if (value == null) return;

        this._icon = value;
    }

    get icon(): any {
        return this._icon;
    }

    private _iconSizePx = 10;
    @Input() set iconSizePx(value: number) {
        if (value == null) return;

        this._iconSizePx = value;
    }

    get iconSizePx(): number {
        return this._iconSizePx;
    }
}
