<div id="ol-service-cats-container">
    <ohh-full-vw-w-img-constrained-container backgroundImage="ohh-svc-cats.png">
        <div id="ol-page-banner"></div>
    </ohh-full-vw-w-img-constrained-container>

    <ohh-section-header [topPadding]="40" [bottomPadding]="40">
        <div class="ol-sh-heading">
            <span class="ol-uppercase">Search for <strong>Top-Tier</strong> Experts</span>
        </div>
        <div class="ol-sh-body small-margin">
            Discover professionals with ease. Simply click on a category to explore visually engaging and interactive profiles of experts within your local communities.
        </div>
    </ohh-section-header>

    <ohh-full-vw-constrained-container>
        <ohh-mega-menu [hideHeader]="true" [nonModalUsage]="true"></ohh-mega-menu>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>

</div>
