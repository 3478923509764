import EmailType = OhhEnums.EmailType;
import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthManagerService } from '../../api/auth/auth-manager.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ohh-email-sent',
    templateUrl: './email-sent.component.html',
    styleUrl: './email-sent.component.less'
})
export class EmailSentComponent implements OnInit {

    EmailType = EmailType;

    protected text: string;
    protected emailType: EmailType;
    protected email: string;

    constructor(private authManager: AuthManagerService,
                private messageService: OhhMessageService,
                private route: ActivatedRoute,
                private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.text = this.route.snapshot.data['text'];
        this.emailType = this.route.snapshot.data['emailType'];
        this.email = this.route.snapshot.queryParamMap.get('email');
    }

    protected onSendEmailVerification() {
        if (this.email == null) return;

        this.authManager.sendEmailConfirmation(encodeURIComponent(this.email))
            .subscribe({
                next: () => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.SUCCESS'),
                        this.translateService.instant('PHRASE.EMAIL_CONFIRMATION_SENT'),
                        MessageSeverity.Success);
                },
                error: err => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.ERROR'),
                        this.translateService.instant('PHRASE.UNABLE_TO_SEND_CONFIRMATION_LINK'),
                        MessageSeverity.Error);
                }
            })
    }

    protected onForgotPassword() {
        this.authManager.forgotPassword(this.email)
            .subscribe({
                next: () => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.SUCCESS'),
                        this.translateService.instant('PHRASE.RESET_PASSWORD_LINK_SENT'),
                        MessageSeverity.Success);
                },
                error: err => {
                    this.messageService.showMessage(
                        this.translateService.instant('STRINGS.ERROR'),
                        this.translateService.instant('PHRASE.UNABLE_TO_SEND_PASSWORD_RESET_LINK'),
                        MessageSeverity.Error);
                }
            })
    }
}
