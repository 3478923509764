<div id="ol-menubar-container" [ngClass]="{'logged-in': isUserLoggedIn}">

    <div *ngIf="!isUserLoggedIn" id="ol-menu-join" class="ol-menu-item" (click)="op.toggle($event)">
        <span class="prevent-select">{{ 'CASE_SENSITIVE.JOIN_US' | translate }} <fa-icon icon="chevron-down"></fa-icon></span>
    </div>
    <div *ngIf="!isUserLoggedIn" id="ol-menu-login" class="ol-menu-item" (click)="onSignIn($event)">
        <span class="prevent-select">{{ 'CASE_SENSITIVE.LOG_IN' | translate }}</span>
    </div>

    <div id="ol-avatar-container" pRipple *ngIf="isUserLoggedIn" [ngClass]="{'no-image': avatarImageUri == null}" (click)="onAvatarClick($event)">
        <p-avatar
                class="prevent-select"
                styleClass="ohh-avatar"
                [label]="avatarImageUri == null ? avatarLabel : null"
                [image]="avatarImageUri"
                size="large"
                shape="circle"
        ></p-avatar>
        <div id="ol-icon-container" class="prevent-select">
            <fa-icon icon="chevron-down"></fa-icon>
        </div>
    </div>
    <p-menu #avatarMenu [model]="avatarMenuItems" [popup]="true" appendTo="body"></p-menu>

    <p-overlayPanel #op styleClass="ol-submenu-panel">
        <ng-template pTemplate>
            <div id="ol-sub-menubar">
                <div *ngIf="!isUserLoggedIn" class="ol-menu-item" (click)="onNewCustomer($event)">
                    <span class="prevent-select">{{ 'CASE_SENSITIVE.NEW_CUSTOMER' | translate }}</span>
                </div>
                <div class="ol-menu-item" (click)="onNewProvider($event)">
                    <span class="prevent-select">{{ 'CASE_SENSITIVE.OFFER_YOUR_SERVICES' | translate }}</span>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>
