<div id="ol-neighborhood-container" class="ol-page-container">
    <ohh-full-vw-w-img-constrained-container
            [baseImagePath]="imagePath"
            [backgroundImage]="city?.bannerImage">
        <div id="ol-page-banner"></div>
    </ohh-full-vw-w-img-constrained-container>

    <ohh-section-header [topPadding]="40" [bottomPadding]="!deviceManager.isMobile ? 40 : 20">
        <div class="ol-sh-heading">
            <span class="ol-city-name"><strong>{{ city?.name }}</strong><span *ngIf="city?.state">, {{ city?.state }}</span></span>
        </div>
        <div *ngIf="city?.cityList != null" class="ol-sh-sub-heading small-margin">
            <span>{{ city.cityList }}</span>
        </div>
        <div class="ol-sh-body small-margin">
            <!--            {{ cityName }}-->
        </div>
    </ohh-section-header>

    <ohh-under-construction *ngIf="city?.summaryText == null"></ohh-under-construction>

    <ohh-full-vw-constrained-container [bottomPadding]="50">
        <div *ngIf="city?.summaryText != null" id="ol-city-summary" class="prevent-select">
            <div [innerHTML]="city?.summaryText"></div>
            <div *ngIf="city?.summaryImage" class="ol-city-summary-img" [ngStyle]="{'background-image': 'url(' + imagePath + city?.summaryImage + ')'}"></div>
            <div *ngIf="city?.summaryImage == null" class="ol-city-summary-img"></div>
        </div>

        <div *ngIf="!deviceManager.isMobile" id="ol-city-highlights" class="prevent-select">
            <div class="ol-ch-col one">
                <div class="ol-city-highlight" [innerHTML]="city?.highlightsIntro"></div>
                <ng-container *ngFor="let h of city?.highlights; let i = index">
                    <div class="ol-city-highlight" *ngIf="i % 2 === 0" [innerHTML]="addIcon(h)"></div>
                </ng-container>
            </div>
            <div class="ol-ch-col two">
                <ng-container *ngFor="let h of city?.highlights; let i = index">
                    <div class="ol-city-highlight" *ngIf="i % 2 !== 0" [innerHTML]="addIcon(h)"></div>
                </ng-container>
            </div>
        </div>

        <div id="ol-city-map-container" *ngIf="mapOptions != null">
            <div id="ol-cm-fixed-container">
                <google-map [options]="mapOptions" width="100%" [height]="!deviceManager?.isMobile ? '500px' : '350px'">
<!--                    <map-marker *ngFor="let marker of mapMarkers" [position]="marker"></map-marker>-->
					<map-advanced-marker *ngFor="let marker of mapMarkers" [position]="marker.position"></map-advanced-marker>
                </google-map>
            </div>
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container>
        <ohh-section-header *ngIf="!deviceManager.isMobile" [topPadding]="40" [bottomPadding]="!deviceManager.isMobile ? 40 : 20">
            <div id="ol-city-footer-text">
                <div class="ol-sh-heading smaller-text">
                    <span class="ol-city-name"><span *ngIf="city?.cityList == null">City of </span><strong>{{ city?.name }}</strong></span>
                </div>
                <div class="ol-sh-body small-margin" [innerHTML]="city?.footerText"></div>
            </div>
        </ohh-section-header>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container *ngIf="!deviceManager.isMobile" [bottomPadding]="80">
        <div style="width: 80%; margin-left: auto; margin-right: auto;">
            <ohh-footer-carousel [links]="city?.footerLinks"></ohh-footer-carousel>
        </div>
    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>
</div>
