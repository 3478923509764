import HttpStatusCode = OhhEnums.HttpStatusCode;

import { BehaviorSubject, filter, Observable, switchMap, take, throwError } from 'rxjs';
import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthManagerService } from './auth-manager.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { LoginResponse } from './models/login-response.model';
import { OhhEnums } from '../../common/enumerations/ohh.enums';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly ERROR_1 = 'Error_1';

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingAssetTrackingToken = false;
    assetTrackingTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // It turns out that in an HttpInterceptor, you cannot inject anything that has a dependency on HttpClient into the constructor.
    // So to get around this, you simply need to inject the Injector Angular class into the HttpInterceptor constructor and then use
    // that in a JIT way within the injector.
    // userManager: UserManagerService;

    constructor(private localCacheManager: LocalCacheService,
                private injector: Injector
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const accessToken = this.localCacheManager.ohhApiAccessToken;
        let copiedRequest = req;

        if (accessToken == 'null') {
            console.log();
        }

        if ((accessToken && accessToken !== '')
            && !req.url.includes(environment.ohhIdentityUrl)
            && !req.url.includes('/assets/')
        //     && !req.url.includes('blob.core.windows.net')
        //     && !req.url.includes('api.greensightag.com')
        //     && !req.url.includes('connect.calamp.com')
        //     && !req.url.includes(environment.assetTrackingApiUrl)
        ) {
            copiedRequest = req.clone({
                headers: req.headers
                    .append('Authorization', `bearer ${accessToken}`)
            });
        }

        return next.handle(copiedRequest)
            .pipe(
                catchError((error: Response, caught: Observable<any>) => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case HttpStatusCode.BadRequest:
                                return this.handle400Error(error);
                            case HttpStatusCode.Unauthorized:
                                return this.handle401Error(req, next);
                            case HttpStatusCode.Forbidden:
                                // LoggingService.error('User does not have access to requested resource');
                                return throwError(error);
                            case HttpStatusCode.FailedDependency:
                                // Currently used when myTurf credentials do not exist or have expired.
                                return throwError(error);
                        }

                        return throwError(caught);
                    } else {
                        return throwError(error);
                    }
                })
            );

        // return next.handle(req);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

        // Handle standard IntelliDash API missing or expired token.
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authManager = this.injector.get(AuthManagerService);

            return authManager.ohhApiLoginWithRefreshToken()
                .pipe(
                    switchMap((response: LoginResponse) => {
                        this.tokenSubject.next(response.token);
                        const copiedRequest = req.clone({ headers: req.headers.append('Authorization', `bearer ${response.token}`) });

                        this.isRefreshingToken = false;
                        return next.handle(copiedRequest);
                    }),
                    catchError((error: any) => {
                        if (error instanceof HttpErrorResponse && (<HttpErrorResponse>error).status === 400) {
                            // If there is an exception calling 'refreshToken', bad news so logout.
                            return this.handle400Error(error);
                        }

                        return throwError(error);
                    })
                );
        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        const copiedRequest = req.clone({ params: req.params.append('Authentication', token) });
                        return next.handle(copiedRequest);
                    })
                );
        }
    }

    handle400Error(error: any) {
        if (error && error.status === 400 && this.isRefreshTokenInvalid(error)) {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            const authManager = this.injector.get(AuthManagerService);
            authManager.ohhApiLogOut();
            return throwError(error);
        }

        return throwError(error);
    }

    private isRefreshTokenInvalid(error: HttpErrorResponse) {
        // State when the refreshToken exists, but is expired. This will be thrown by the server.
        if (error.error && error.error[this.ERROR_1] && error.error[this.ERROR_1][0] && error.error[this.ERROR_1][0].includes('token is expired')) {
            return true;
        }

        // State when the refreshToken does not exist. This will be thrown by userManager.loginWithRefreshToken()
        if (error.statusText === 'no refresh token found') {
            return true;
        }

        return false;
    }



}
