import { Directive, OnInit } from '@angular/core';
import { BroadcastService } from '../../common/services/broadcast.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';

@Directive()
export abstract class ComponentBase implements OnInit {

    // Constants
    protected readonly OhhColors = OhhEnums.OhhColors;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected broadcastService: BroadcastService) { }

    ngOnInit() {
        this.broadcastService.scrollToTop.next(null);
    }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================

}
