<div id="ol-provider-signup-container" class="ol-page-container">
    <ohh-section-header [topPadding]="40" [bottomPadding]="40">
        <div class="ol-sh-heading">
            <span>Provider Sign Up</span>
        </div>
        <!--        <div class="ol-sh-body small-margin">-->
        <!--            -->
        <!--        </div>-->
    </ohh-section-header>

    <ohh-under-construction></ohh-under-construction>

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>
</div>
