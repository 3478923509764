import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginInfo } from './loginInfo';
import { LoginResponse } from './models/login-response.model';
import { RegisterUserRequest } from './models/register-user-request.model';
import { RegisterUserResponse } from './models/register-user-response.model';
import { ResetPwRequest } from './models/reset-pw-request.model';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    ohhApiLogin(loginInfo: LoginInfo): Observable<LoginResponse> {
        return this.apiService.apiRequest<any>(AuthApiService.loginUrl, HttpMethod.Post, loginInfo)
            .pipe(map((response: LoginResponse) => new LoginResponse(response)));
    }

    ohhApiLoginWithRefreshToken(refreshToken: string): Observable<LoginResponse> {
        return this.apiService.apiRequest<any>(AuthApiService.loginWithRefreshTokenUrl, HttpMethod.Post, refreshToken)
            .pipe(map((response: LoginResponse) => new LoginResponse(response)));
    }

    registerUser(registerUserRequest: RegisterUserRequest): Observable<RegisterUserResponse> {
        return this.apiService.apiRequest<any>(AuthApiService.registerUrl, HttpMethod.Post, registerUserRequest)
            .pipe(map((response: RegisterUserResponse) => new RegisterUserResponse(response)));
    }

    confirmEmail(userId: string, token: string): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.confirmEmailUrl(userId, token), HttpMethod.Get)
    }

    sendEmailConfirmation(email: string): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.sendEmailConfirmationUrl(email), HttpMethod.Get)
    }

    forgotPassword(email: string): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.forgotPasswordUrl(email), HttpMethod.Get)
    }

    resetPassword(model: ResetPwRequest): Observable<any> {
        return this.apiService.apiRequest<any>(AuthApiService.resetPasswordUrl, HttpMethod.Post, model)
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${environment.ohhIdentityUrl}/api/auth`; }

    private static get loginUrl(): string { return `${AuthApiService.baseUrl}/login`; }

    private static get registerUrl(): string { return `${AuthApiService.baseUrl}/register`; }

    private static get loginWithRefreshTokenUrl(): string { return `${AuthApiService.baseUrl}/token`; }

    private static confirmEmailUrl(userId: string, token: string): string {
        return `${AuthApiService.baseUrl}/confirmEmail?userId=${userId}&token=${token}`
    }

    private static sendEmailConfirmationUrl(email: string): string {
        return `${AuthApiService.baseUrl}/SendEmailConfirmation?email=${email}`
    }

    private static forgotPasswordUrl(email: string): string {
        return `${AuthApiService.baseUrl}/forgotPassword?email=${email}`
    }

    private static get resetPasswordUrl(): string { return `${AuthApiService.baseUrl}/resetPassword`; }

}
