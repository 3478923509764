import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    constructor() { }

    passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const password = control.get('password');
            const confirmPassword = control.get('confPassword');

            return password && confirmPassword && password.value !== confirmPassword.value ? { 'passwordMismatch': true } : null;
        };
    }
}
