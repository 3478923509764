import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorLoggingService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: '84e13ada-ed55-4416-aee7-5d02bb08c5ea'  // Replace with your Azure App Insights key
            }
        });
        this.appInsights.loadAppInsights();
    }

    handleError(error: any): void {
        // Log the error to Application Insights
        this.appInsights.trackException({ exception: error });

        // Optionally, log it to the console or a custom log system
        console.error('Error logged to Application Insights:', error);
    }
}
