export class ResetPwRequest {

    constructor(email: string, password: string, token: string) {
        this.email = email;
        this.password = password;
        this.token = token;
    }

    email: string;
    password: string;
    token: string;
}
