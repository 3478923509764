import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhContactMethod } from '../provider/models/ohh-contact-method.model';

@Injectable({
    providedIn: 'root'
})
export class ContactMethodApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getContactMethods(): Observable<OhhContactMethod[]> {
        return this.apiService.apiRequest<any>(ContactMethodApiService.getContactMethodsUrl, HttpMethod.Get)
            .pipe(map((result: OhhContactMethod[]) => result.map(c => new OhhContactMethod(c))));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}contactMethods`; }

    private static get getContactMethodsUrl(): string { return `${ContactMethodApiService.baseUrl}`; }

}
