<div class="ol-cp-home-container">
	<p-accordion [activeIndex]="0">
		<p-accordionTab [header]="'User Profile' | translate">

			<div class="ol-cp-account-container">
				<div class="ol-profile-section-container">
					<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.UPLOAD_PERSONAL_PROFILE_PICTURE' | translate }}</div>

					<div class="ol-cp-drop-wrapper">
						<div class="olps-img-container"
							 [ngClass]="{'placeholder': profileImageUri == null}"
							 [ngStyle]="{'background-image': 'url(' + (profileImageUri == null ? placeholderImageUrl : '') + ')'}"
						>
							<img *ngIf="profileImageUri != null" [src]="profileImageUri" alt="Profile Picture">
						</div>
						<div class="ol-cp-drop-container file-drop-zone"
							 (dragover)="onDragOver($event)"
							 (dragleave)="onDragLeave($event)"
							 (drop)="onDrop($event)"
						>
							<input type="file" #fileInput (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png" style="display: none;"/>
							<button class="ol-green-button inverted" (click)="onUploadFile()">{{ 'CASE_SENSITIVE.UPLOAD_A_FILE' | translate }}</button>
							<span class="ol-cp-drop-text">...{{ 'STRINGS.OR_DRAG_DROP_FILE' | translate }}</span>
						</div>
					</div>

				</div>

				<div class="ol-profile-section-container">
					<div class="olps-header prevent-select">{{ 'CASE_SENSITIVE.PERSONAL_INFORMATION' | translate }}</div>

					<form [formGroup]="formGroup">

						<div class="olps-content-container">

							<div id="ol-cp-user-info">

								<div class="ol-info-col left">
									<div class="ol-labeled-input-text">
										<label for="firstName" class="prevent-select">First Name*</label>
										<input pInputText id="firstName" formControlName="firstName">
										<div class="ol-field-error" *ngIf="fieldIsInvalid('firstName')">
											<span *ngIf="formGroup.controls['firstName']?.errors?.['required']">First Name is required.</span>
										</div>
									</div>
									<div class="ol-labeled-input-text">
										<label for="lastName" class="prevent-select">Last Lame*</label>
										<input pInputText id="lastName" formControlName="lastName">
										<div class="ol-field-error" *ngIf="fieldIsInvalid('lastName')">
											<span *ngIf="formGroup.controls['lastName']?.errors?.['required']">Last Name is required.</span>
										</div>
									</div>

									<div class="ol-labeled-input-text">
										<label for="phoneNumber" class="prevent-select">Phone Number</label>
										<input pInputText id="phoneNumber" formControlName="phoneNumber">
									</div>

									<div class="ol-labeled-input-text">
										<label for="email" class="prevent-select">Email</label>
										<input pInputText id="email" formControlName="email">
									</div>
								</div>

								<div class="ol-info-col right">
									<div class="ol-labeled-input-text">
										<label for="address1" class="prevent-select">Address</label>
										<input pInputText id="address1" formControlName="address1">
									</div>
									<div id="ol-city-state">
										<div class="ol-labeled-input-text">
											<label for="city" class="prevent-select">City</label>
											<input pInputText id="city" formControlName="city">
										</div>
										<div class="ol-labeled-input-text">
											<label for="state" class="prevent-select">State</label>
											<input pInputText id="state" formControlName="state">
										</div>
									</div>
									<div class="ol-labeled-input-text">
										<label for="postalCode" class="prevent-select">Zip Code</label>
										<input pInputText id="postalCode" formControlName="postalCode">
									</div>
								</div>

							</div>

							<div id="ol-btn-container">
								<button class="ol-green-button inverted" [disabled]="!isFormDirtyAndValid()" (click)="onCancel()">Cancel</button>
								<button class="ol-green-button" (click)="onSubmit()" [disabled]="!isFormDirtyAndValid()">Save</button>
							</div>

						</div>

					</form>

				</div>

<!--				<ohh-waiting-indicator *ngIf="isSaving" [backgroundColor]="'#c8c8c81A'" [blurBackground]="true"></ohh-waiting-indicator>-->
			</div>

		</p-accordionTab>
	</p-accordion>

</div>

<ohh-image-cropper-dlg
		*ngIf="showImageSelectionDlg"
		[title]="'PHRASE.SELECT_PROFILE_IMAGE' | translate"
		[initImage]="initFile"
		[imgRatioNumerator]="profileImageType == ImageType.Profile ? 1 : 1.52"
		(save)="onSaveProfileImage($event)"
		(cancel)="showImageSelectionDlg = false;"
></ohh-image-cropper-dlg>
