import { Directive, OnDestroy, OnInit } from '@angular/core';
import { CanComponentDeactivate } from '../../common/guards/unsaved-changes.guard';

@Directive()
export abstract class EditableComponent implements OnInit, OnDestroy, CanComponentDeactivate {

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    ngOnInit() {
        // Add a listener for the browser unload event
        window.addEventListener('beforeunload', this.beforeUnloadListener);
    }

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.beforeUnloadListener);
    }

    canDeactivate(): boolean {
        if (this.hasChanges()) {
            return confirm('You have unsaved changes! Are you sure you want to leave this page?');
        }
        return true;
    }

    // =========================================================================================================================================================
    // Abstract Methods
    // =========================================================================================================================================================

    protected abstract hasChanges(): boolean;

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private beforeUnloadListener = (event: BeforeUnloadEvent) => {
        if (this.hasChanges()) {
            event.preventDefault();
            event.returnValue = ''; // Required for Chrome to show the dialog
            return '';
        }

        return null;
    }

}
