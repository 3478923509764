<!--<div style="margin: 15px;">-->
<!--    <p>-->
<!--        Category Page: {{category?.name}}-->
<!--        <br><span *ngIf="subCategory != null">Sub Category: {{subCategory?.name}}</span>-->
<!--    </p>-->
<!--</div>-->

<div id="ol-services-container">

    <ohh-full-vw-constrained-container [bottomPadding]="50">
        <div id="ol-split-container" >
<!--            <div id="ol-split-sidebar">-->
<!--                Sidebar-->
<!--            </div>-->
            <div id="ol-split-page">
                <ohh-section-header>
                    <div class="ol-sh-marquee">
                        <span>Search Categories</span>
                    </div>
                    <div class="ol-sh-heading no-sep">
                        <span class="ol-uppercase">Browse and click <strong>Profiles To Connect</strong></span>
                    </div>
                    <div class="ol-sh-body small-margin ol-capitalize">
                        See Videos, Reviews, Skills and Schedules
                    </div>
                </ohh-section-header>

                <div id="ol-mobile-search-results-header" *ngIf="true || deviceManager.isLessThanMedium">
                    <span>{{category?.name}}</span>
                    <span *ngIf="subCategory != null">: {{subCategory?.name}}</span>
                </div>

                <div id="ol-provider-card-container" *ngIf="!areNoProviders">
                    <ohh-provider-card
                            *ngFor="let p of providers"
                            [isDemo]="isDemo"
                            [provider]="p"
                            (buttonClick)="onProviderCardClicked($event)"
                    ></ohh-provider-card>
                </div>

				<div id="ol-no-providers-container" *ngIf="areNoProviders">
					<span id="ol-no-providers">{{ 'PHRASE.NO_PROVIDERS_IN_CAT_MSG' | translate}}</span>
				</div>
            </div>
        </div>

    </ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>


<!--    <div id="ol-categories-tab">-->
<!--        <span id="ol-tab-text" class="prevent-select">Category - {{ 'Auto Repair' }}</span>-->
<!--    </div>-->

<!--    <div *ngIf="!deviceManager.isLessThanMedium" id="ol-search-tab" [ngClass]="{'show': showSearchTab}">-->
<!--        <div id="ol-search-tab-content">-->
<!--            <div id="ol-categories-tab" (click)="onShowSearchTab()">-->
<!--                <span id="ol-tab-text" class="prevent-select">Category - {{ subCategory?.name || category?.name || '' }}</span>-->
<!--            </div>-->

<!--            <ohh-services-panel [isDesktop]="true" (categorySelected)="onCategorySelected()"></ohh-services-panel>-->
<!--        </div>-->
<!--    </div>-->

</div>


