<div id="ol-wi-container"
	 [style.background]="backgroundColor"
	 [style.z-index]="zIndex"
	 [ngClass]="{'blur-background': blurBackground, 'fade-out': startFadeOut, 'fixed': fixed}
">
    <p-progressSpinner *ngIf="!hideSpinner" ariaLabel="loading" styleClass="ol-progress-spinner" [style]="{'--p-progress-spinner-color': '#4CAF50'}" />

	<div *ngIf="text != null" id="ol-wi-text">
		<div>{{ text }}</div>
		<div *ngIf="subText != null">{{ subText }}</div>
	</div>

	<div *ngIf="canCancel" >
		<div class="ol-green-button inverted" (click)="onCancel()">
			<span class="prevent-select">Cancel</span>
		</div>
	</div>
</div>
