import * as moment from 'moment/moment';

export class OhhProviderReview {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.dateAddedUtc) this.dateAddedUtc = moment.utc(json.dateAddedUtc).toDate();
        }
    }

    id: string;
    providerInfoId: string;
    reviewTitle: string;
    review: string;
    rating: number;
    client: string;
    city: string;
    dateOfReview: string;
    dateAddedUtc: Date;
}
