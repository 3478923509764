import { Component, Input, OnInit } from '@angular/core';
import { OhhServiceCategory } from '../../../api/service-category/models/ohh-service-category.model';
import { Router } from '@angular/router';

@Component({
    selector: 'ohh-carousel',
    templateUrl: './carousel.component.html',
    styleUrl: './carousel.component.less'
})
export class CarouselComponent implements OnInit {

    private _categories: OhhServiceCategory[] = [];
    @Input() set categories(value: OhhServiceCategory[]) {
        if (value == null) return;

        this._categories = value;
    }

    get categories(): OhhServiceCategory[] {
        return this._categories;
    }

    responsiveOptions: any[] | undefined;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private router: Router){}

    ngOnInit(): void {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCardClick(category: any) {
        // this.router.navigate(['/services'], { queryParams: { category: category.id } });
        this.router.navigate(['/service-categories']);
    }

}
