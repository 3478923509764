import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { Router } from '@angular/router';

@Component({
    selector: 'ohh-early-sign-up',
    templateUrl: './early-sign-up.component.html',
    styleUrl: './early-sign-up.component.less',
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition('void => *', [
                style({ opacity: 0 }),
                animate(1000) // Adjust the duration as needed
            ]),
            transition('* => void', [
                animate(1000, style({ opacity: 0 })) // Adjust the duration as needed
            ])
        ])
    ]
})
export class EarlySignUpComponent extends ComponentBase implements ComponentBase, OnInit, OnDestroy {

    showFirstImage = true;
    intervalId: any;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                private router: Router
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.intervalId = setInterval(() => {
            this.toggleBackground();
        }, 5000); // Switch every 5 seconds
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onGetFound() {
        this.router.navigate(['/provider-sign-up']);
    }

    protected onViewServices() {
        this.router.navigate(['/service-categories']);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private toggleBackground() {
        this.showFirstImage = !this.showFirstImage;
    }

}
