import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, Input } from '@angular/core';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';

@Component({
    selector: 'ohh-email-sign-up',
    templateUrl: './email-sign-up.component.html',
    styleUrl: './email-sign-up.component.less'
})
export class EmailSignUpComponent {

    @Input() termWidthPx = 290;
    @Input() zipWidthPx = 130;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private ohhMessageService: OhhMessageService
    ) {

    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSubmit() {
        this.ohhMessageService.showMessage('Click Action', 'Signs up for newsletter.', MessageSeverity.Success);
    }
}
