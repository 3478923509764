export class CropperImageInfo {

    constructor(blobName: string, blob: Blob) {
        this.blobName = blobName;
        this.blob = blob;
    }

    blob: Blob;
    blobName: string;
}
