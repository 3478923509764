export class OhhServiceCategory {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.subCategories) this.subCategories = json.subCategories.map((c: OhhServiceCategory) => new OhhServiceCategory(c));
        }
    }

    id: string | undefined;
    name = '';
    image = '';
    order: number;
    subCategories: OhhServiceCategory[] = [];
}
