import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';

export const customerGuard: CanActivateFn = (route, state) => {

    const ohhUserManager = inject(OhhUserManagerService);
    const router = inject(Router);

    return new Observable(observer => {
        ohhUserManager.getUserByToken()
            .subscribe({
                next: (user: OhhUser) => {
                    if (user == null) { router.navigate(['/log-in']); }

                    observer.next(user != null);
                    observer.complete();
                },
                error: err => {
                    router.navigate(['/log-in']);
                    observer.next(false);
                    observer.complete();
                }
            });
    });
};
