<div id="ol-carousel-container">

    <p-carousel
            styleClass="ohh-carousel"
            [value]="categories"
            [numVisible]="3"
            [numScroll]="3"
            [circular]="false"
            [responsiveOptions]="responsiveOptions">

        <ng-template let-category pTemplate="item">

            <div id="ol-car-card-container" (click)="onCardClick(category)">
                <div id="ol-car-card-img-container" class="prevent-selectX" style="max-width: 100%; height: 250px; margin: 10px 20px; overflow: hidden; box-shadow: 0px 2px 5px rgba(0,0,0,0.2);">
                    <img class="prevent-select" style="width: 100%; height: 100%; object-fit: cover;"
                         src="../../../assets/images/{{ category.image }}"
                         [alt]="category.name | translate"/>
                </div>
                <div id="ol-car-card-lbl-container" class="prevent-select ol-font-light" style="display: flex; justify-content: center; padding: 10px; font-family: 'proxima-nova', sans-serif; font-size: clamp(12px, calc(10px + .5vw), 18px)">
                    {{ category.name | translate | uppercase }}
                </div>
            </div>

        </ng-template>

    </p-carousel>

</div>
