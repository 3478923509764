export class OhhProviderInfoUpdate {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);

            if (json.primaryService) this.service = json.primaryService;
            if (json.insured) this.isInsured = json.insured;
            if (json.website) this.websiteUrl = json.website;
            if (json.category) this.categories = [json.category];

            delete (this as any)['primaryService'];
            delete (this as any)['insured'];
            delete (this as any)['website'];
            delete (this as any)['category'];
        }
    }

    id: string;
    businessName: string;
    service: string;
    businessEmail: string;
    businessPhone: string;
    professionalTitle: string;
    rate: number;
    contactForRate = false;
    isInsured = false;
    introduction: string;
    experience: string;
    whyHire: string;
    services: string;
    websiteUrl: string;
    instaUrl: string;
    facebookUrl: string;
    linkedInUrl: string;
    youTubeUrl: string;
    tikTokUrl: string;
    categories: string[] = [];
    subCategories: string[] = [];
    contactMethods: string[] = [];
    paymentMethods: string[] = [];
    specialties: string[] = [];
    locations: string[] = [];
}
