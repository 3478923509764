<div #contentDiv id="ol-read-more-container"  [ngStyle]="{'height': height + 'px', 'max-height': contentHeight + 'px' }">
    <div [ngClass]="{'prevent-select': !allowSelect }">
        <ng-content></ng-content>
    </div>

    <div id="ol-rm-overlay"
         *ngIf="hasScrollbar || contentHeight > defaultHeight"
         [ngClass]="{'hidden': !isInitialized}"
         (click)="onToggleExpansion()">
        <span class="prevent-select">...{{ buttonLabel | translate }}</span>
    </div>
</div>
