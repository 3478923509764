import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import ImageType = OhhEnums.ImageType;

export class OhhImageInfo {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    id: string;
    userId: string;
    blobName: string;
    uri: string;
    type: ImageType
    order: number;

}
