import ImageType = OhhEnums.ImageType;

import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhImageInfo } from '../../ohh-user/models/ohh-image-info.model';
import { OhhProviderInfo } from './ohh-provider-info.model';
import { OhhUser } from '../../ohh-user/models/ohh-user.model';

export class OhhProvider extends OhhUser {

    constructor(json: any) {
        super(json);

        if (json) {
            Object.assign(this, json);

            if (json.providerInfo) this.providerInfo = new OhhProviderInfo(json.providerInfo);
        }
    }

    providerInfo: OhhProviderInfo;

    get businessProfileImage(): OhhImageInfo {
        return this.images?.find((image: OhhImageInfo) => image.type == ImageType.BusinessProfile);
    }

    get galleryImages(): OhhImageInfo[] {
        return this.images?.filter(i => i.type == ImageType.BusinessGallery)?.sort((a, b) => a.order - b.order);
    }
}
