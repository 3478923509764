import MessageSeverity = OhhEnums.MessageSeverity;

import { OhhEnums } from '../../enumerations/ohh.enums';

export class OhhMessageItem {

    constructor(summary: string, detail: string, severity = MessageSeverity.Warn) {
        this.severity = severity;
        this.summary = summary;
        this.detail = detail;
    }

    severity: string;
    summary: string;
    detail: string;

    life = 5000;
    sticky = false;
}
