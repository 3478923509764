import { Component, Input } from '@angular/core';

@Component({
    selector: 'ohh-section-header',
    templateUrl: './section-header.component.html',
    styleUrl: './section-header.component.less'
})
export class SectionHeaderComponent {

    @Input() topPadding: number;
    @Input() bottomPadding: number;

}
